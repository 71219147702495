import RenderedGrid from "@/components/grid/RenderedGrid";
import RenderedGridItem from "@/components/grid/RenderedGridItem";

import { useDatapoolStore } from "@/stores/datapool";
import { useNodeStore } from "@/stores/node";
import * as ViewUIPlusComponents from "view-ui-plus";
import { h, inject, ref } from "vue";
import { plotRender } from "./render.js";

let GridPlotRender;
const components = {
  RenderedGridItem,
  RenderedGrid,
  ...ViewUIPlusComponents,
};

GridPlotRender = {
  name: "GridPlotRender",
  props: {
    plot: {
      type: Object,
    },
    scene: {
      type: String,
    },
    params: {
      type: Object,
    },
    // preNode: {
    //   type: Object,
    // },
  },
  // provide() {
  //   return {
  //     preNode: this?.params?.preNode,
  //   };
  // },
  setup(props) {
    //获取视图
    let plot = props?.plot;
    const scene = props?.scene;
    if (scene) {
      const datapoolStore = useDatapoolStore();
      plot = datapoolStore.getDatapoolByIndex("plots", scene, "scene");
    }
    //设定 传参内部变量
    const params = props?.params;
    //设定 视图内部变量
    const plotProperty = props.plot?.property;
    const plotParams = {};
    if (plotProperty) {
      plotProperty.map((n) => {
        plotParams[n.key] = ref(n.value);
      });
    }
    const nodeStore = useNodeStore();

    const _curNode = inject("_curNode");
    const contextFunctions = {
      curNode: () => {
        return this?._curNode;
      },
    };
    const context = ref({
      $states: nodeStore.plotStates, //设定 全局状态变量
      $frame: nodeStore.frame,
      ...plotParams,
      ...params,
      _curNode,

      ...contextFunctions, //设定 内部方法
    });
    // provide(/* 注入名 */ '_curNode', /* 值 */ params._curNode)

    return () => plotRender(h, plot?.viewStruct, { context });
  },
};
export default GridPlotRender;
