<template>
  <div>
    <InputNumber v-for="(num, i) in od" :key="i" :min="0" v-model="od[i]" @on-change="makeOd"
      style="width: 54px;margin-right: 6px;" />
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => {
        return [0];
      },
    },
  },
  // props: ['modelValue'],
  emits: ['update:modelValue'],
  // model: {
  //   prop: "value",
  //   event: "change",
  // },
  data() {
    return {
      od: [],
    };
  },
  computed: {
    // schemeList() {
    //   return this.$store.state.datapool.schemes;
    // },
  },
  methods: {
    makeOd() {
      // console.log("this.od", this.od);
      for (let index in this.od) {
        if (this.od[index] === null) {
          this.od.splice(index, 1);
        }
      }
      if (this.od[this.od.length - 1] !== null) {
        this.od.push(null);
      }
      let value = this.od.slice(0, -1);

      console.log("value", value, this.od);
      this.$emit("update:modelValue", value);
      console.log("value", value, this.od);

      // this.od.push(null);
    },
    // updateVal(value) {
    //   console.log("change", value);
    //   this.$emit("change", value);
    // },
  },
  mounted() {
    console.log("this.od1", this.od, this.modelValue);

    Object.assign(this.od, this.modelValue);
    console.log("this.od", this.od, this.modelValue);
    //  this.od = this.value;
    if (this.od[this.od.length - 1] !== null) {
      this.od.push(null);
    }
  },
};
</script>
