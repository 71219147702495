// const files = import.meta.globEager("./*.vue");
const files = import.meta.glob("./*.vue", { eager: true });
// const context = require.context("./inputs", false, /\.vue$/);
let inputs = {};

// for (const key of context.keys()) {
//     inputs[key.replace("./", "").replace(".vue", "")] = context(key);
// }
// export default inputs;
Object.keys(files).forEach((fileName) => {
  const name = fileName.replace(/\.\/|\.vue/g, "");

  inputs[name] = files[fileName].default;
});
export default inputs;
