<template>
  <div>
    <Button v-if="cards?.length > 0 && !firstDepth" type="text" shape="circle"
      :icon="showSub ? 'md-arrow-dropdown' : 'md-arrow-dropleft'" @click="showSub = !showSub" class="show-sub"></Button>
    <template v-if="showSub">
      <template v-for="(card, index) in cards">
        <PlotRender :key="card?.node.vid" v-if="inItemVariety(card.node)" :scene="itemSceneSelect(card.node)"
          @click="showView(card.node, index)" @click.right="showViewRight(index)" :class="[
            'card-item-wrap', activeId === card.node.vid ? 'active' : ''
          ]" :params="{ _curNode: card.node, card: cards[index].node }" :card="cards[index].node" />

        <template v-if="card.sub && card.sub.length > 0 && depth > 0">
          <LevelList v-if="inItemVariety(card.node)" :depth="depth - 1" :unfold_depth="unfold_depth - 1"
            :key="card?.node.vid" :nodes="card.sub" :preNode="card.node" :itemScenes="itemScenes"
            :cardScenes="cardScenes" :clickCardModal="clickCardModal" :cardModalStyle="cardModalStyle"
            :activeId="activeId" :branch="branch" :firstDepth="false" @select="onSelect" style="position: relative;"
            @update:activeId="activeId = $event" class="sub-level">
          </LevelList>
        </template>
      </template>
      <Modal v-model="showCardModal" :styles="cardModalStyle" footer-hide>

        <PlotRender v-if="showCardModal && cards[curCardIndex]?.node" :scene="cardSceneSelect(cards[curCardIndex].node)"
          :params="{ _curNode: cards[curCardIndex].node, preNode: preNode }" :key="cards[curCardIndex].node.vid"
          :style="{ minHeight: '480px' }">
        </PlotRender>

        <div class="card-tool-bar">
          <ButtonGroup>
            <Button @click="lastCard()">
              <Icon type="ios-arrow-back"></Icon>
              上一卡
            </Button>
            <Button @click="nextCard()">
              下一卡
              <Icon type="ios-arrow-forward"></Icon>
            </Button>
          </ButtonGroup>
        </div>
      </Modal>
    </template>
  </div>
</template>
<script>
// import LevelListItem from "./LevelListItem.vue";
// import CardPlotRender from "@/components/plotRender/CardPlotRender.js";
// import PlotRender from "@/components/plotRender/PlotRender.js";
import { useNodeStore } from '@/stores/node';
//const nodeStore = useNodeStore()
import _ from "lodash-es";

export default {
  name: "LevelList",
  props: {
    branch: String,
    nodes: Array,

    //  preId: String,
    mountedWatch: Object,
    depth: Number,
    unfold_depth: Number,

    itemScenes: Array,
    cardScenes: Array,
    clickCardModal: {
      type: Boolean,
      default: true,
    },

    preNode: {
      type: Object,
    },
    firstDepth: {
      type: Boolean,
      default: true,
    },
    activeId: String,
    // includeSelf: {
    //   type: Boolean,
    //   default: false,
    // },
    cardModalStyle: {
      type: Object,
      default: function () {
        return {
          top: "100px",
          width: "720px",
          minWidth: "1020px",
          maxWidth: "1600px",
        };
      },
    },
    perPage: { type: Number, default: 20 },
    pageMode: {
      type: String,
      default: "paging",
      validator(value) {
        return ["paging", "full"].includes(value);
      },
    },
  },
  data() {
    return {
      curCardIndex: -1,
      showCardModal: false,
      page: 0,
      curIndex: "",
      paging: {},
      showSub: true,
      current: 1,
      first: true,
      // cards: []
    };
  },
  methods: {

    onSelect(card) {
      this.$emit("select", card)
    },
    showView(card, index) {
      this.$emit("select", card)

      if (!this.clickCardModal) return;
      this.showCardModal = true;
    },
    lastCard() {
      if (this.curCardIndex !== 0) {
        this.curCardIndex--;
      }
    },
    nextCard() {
      if (this.curCardIndex !== this.cards?.length - 1) {
        this.curCardIndex++;
        console.log("this.curCardIndex", this.curCardIndex)
      }
    },
    showViewRight(index) {
      document.oncontextmenu = function (e) {
        return false;
      };
      this.curCardIndex = index;
      this.showCardModal = true;
    },
    itemSceneSelect(card) {
      const item = this.itemScenes?.find((n) => {
        return n.variety in card?.tag
      });
      return item.scene;
    },
    cardSceneSelect(card) {
      const cardModal = this.cardScenes?.find((n) => {
        return n.variety in card?.tag
      });
      return cardModal.scene;
    },
    inItemVariety(card) {
      if (card && !card?.tag) return false;
      const item = this.itemScenes?.find((n) => {
        if (n.variety in card?.tag) {
          return true
        }
        //  return n.variety === card?.node?.variety;
      });
      if (!item) {
        return false;
      } else {
        return true;
      }
    },

    async fetchContext(current = 1, step = 0) {
      try {
        this.loading = true;

        this.$emit("mounted", {
          paging: {
            perPage: step == 0 ? this.perPage : step,
            current: current - 1
          }
        });

      } catch {
        (error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
        }
      } finally {
        this.loading = false;

      }
    },
  },
  computed: {
    cards() {
      if (this.nodes) {
        return this.nodes
      }
      function buildHierarchy(data) {
        if (!data) return []
        // 创建一个对象，用于存储每个节点的引用
        const nodeMap = {};
        const nodes = [];

        // 遍历数据，将每个节点按照其 vid 存储到 nodeMap 中
        data.forEach((item) => {
          if (!item) {
            return
          }
          let node = { node: item };
          nodes.push(node);
          nodeMap[item.vid] = node; //{ node: item };
        });

        // 遍历数据，构建层级结构
        nodes.forEach((item) => {
          // if (!item?.pre) {
          //   item.pre = [];
          // }
          item.pre = [];
          //console.log("item", item);
          item?.node?.edge?.forEach((edge) => {
            const pre = nodeMap[edge.src];
            if (pre) {
              if (!pre?.sub) {
                pre.sub = [];
              }
              // 将当前节点添加为子节点的父节点
              pre.sub.push(item); //= item.vid;
              // 将子节点添加到当前节点的 subren 数组中
              // item.subren.push(sub);
              item.pre.push(pre);
            }
          });
        });
        console.log('buildHierarchy-node', nodes)
        // 返回顶层节点
        return nodes.filter((item) => item?.pre?.length == 0);
      }

      let _cards = this.nodeStore.getBranch({
        branchId: this.branch,
      })
      //console.log('_cards1', _cards, this.branch)
      const hierarchy = buildHierarchy(_cards?.nodes);

      //  console.log('_cards', hierarchy, _cards?.nodes)
      console.log('_cards', _cards, hierarchy)
      //return hierarchy //_cards?.nodes
      if (this.first && hierarchy.length > 0) {
        console.log('firstInit', hierarchy)

        this.$emit("firstInit", hierarchy);
        this.first = false
      }
      if (hierarchy.length > 0) {

        this.$emit("Init", hierarchy);
      }
      return hierarchy //_cards?.nodes
    },

  },
  watch: {
    branch() {
      // this.$emit("cardChenge", this.cards);
    },
    mountedWatch: {
      deep: true,
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (Array.isArray(newVal)) {
          for (let i = 0; i < newVal.length; i++) {
            if (!_.isEqual(newVal[i], oldVal?.[i])) {
              this.fetchContext(1, 0);
              console.log('list mountedWatch', newVal, oldVal)
              break
            }
          }
        } else {
          if (!_.isEqual(newVal, oldVal)) {
            this.fetchContext(1, 0);
            console.log('mountedWatch list2', newVal, oldVal)
          }
        }

        // this.$emit("mounted", {
        //   paging: {
        //     perPage: this.perPage,
        //     current: this.current
        //   }
        // });
        // this.$emit("mounted");
      }
    },

  },
  async mounted() {
    if (this.unfold_depth < 0) {
      this.showSub = false

    }
  },
  async created() {


    //请求节点队列
    // const nodes = await this.nodeStore.initNodes({
    //   position: {
    //     frameId: this.nodeStore.frame._id,
    //     coordinate: this.coordinate,
    //   },
    //   ...this.initCardParams,
    //   depth: 1,
    //   paging: {
    //     perPage: this.perPage,
    //   },
    // })

    //获取节点队列
    //  await this.fetchContext()
    // const selfNode = this.nodeStore.getNodes({
    //   position: {
    //     frameId: this.nodeStore.frame._id,
    //     coordinate: this.coordinate,
    //   }
    // })
    // if (this.includeSelf) {
    //   this.cards = [selfNode];
    // } else {
    //   this.cards = selfNode;
    // }
    //监听节点队列变化
    // this.nodeStore.$subscribe((mutation, state) => {
    //   const selfNode = this.nodeStore.getNodes({
    //     position: {
    //       frameId: this.nodeStore.frame._id,
    //       coordinate: this.coordinate,
    //     }
    //   })
    //   if (this.includeSelf) {
    //     this.cards = [selfNode];
    //   } else {
    //     this.cards = selfNode;
    //   }
    // })
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style lang="less" scoped>
@import url("@/styles/board.less");

.sub-level {
  margin-left: 24px;
  margin-bottom: 1px;
}

.list-fold {
  position: relative;
  right: 1px;
  height: 100%;
  bottom: 0px;
  padding: 3px;
  background: #f5f5f5;
  color: #1b3d6b;
}

.show-sub {
  position: absolute;
  right: 0;
  top: -31px;
}
</style>
