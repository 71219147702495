<template>
  <div id="gchart"></div>
  <!-- <div>{{ nodes }}</div> -->
</template>
<script>

import { useNodeStore } from '@/stores/node';

import { GoogleCharts } from 'google-charts';

export default {
  props: {
    option: Object,
    //title: String,
    id: String,
    branch: String,
    params: { type: Object },
    tagFormat: { type: Object }

  },
  inject: {
    curNode: 'curNode',
  },
  components: {},
  computed: {
    nodes() {
      function buildHierarchy(data) {
        if (!data) return []
        // 创建一个对象，用于存储每个节点的引用
        const nodeMap = {};
        const nodes = [];

        // 遍历数据，将每个节点按照其 vid 存储到 nodeMap 中
        data.forEach((item) => {
          if (!item) {
            return
          }
          let node = { node: item };
          nodes.push(node);
          nodeMap[item.vid] = node; //{ node: item };
        });

        // 遍历数据，构建层级结构
        nodes.forEach((item) => {
          if (!item?.pre) {
            item.pre = [];
          }
          // console.log("item", item);
          item?.node?.edge.sort((a, b) => a.props.sort - b.props.sort);

          item?.node?.edge?.forEach((edge) => {
            const pre = nodeMap[edge.src];
            if (pre) {
              if (!pre?.sub) {
                pre.sub = [];
              }
              // 将当前节点添加为子节点的父节点
              pre.sub.push(item); //= item.vid;
              // 将子节点添加到当前节点的 subren 数组中
              item.pre.push(pre);
            }
          });
          //pre.sub.sort((a, b) => a.sort - b.sort);
        });

        // 返回顶层节点
        return nodes.filter((item) => item?.pre?.length == 0);

      }

      let _cards = this.nodeStore.getBranch({
        branchId: this.branch,
        // depth: depth //this.depth
      })
      const hierarchy = buildHierarchy(_cards?.nodes);

      const sortNodes = []
      function traverseAndSort(array) {

        // 遍历数组中的每个对象
        array.forEach(item => {
          // 如果存在 `sub` 属性且它是一个数组，则递归处理该子数组
          sortNodes.push(item)
          if (Array.isArray(item.sub)) {
            traverseAndSort(item.sub);
          }
        });
      }
      traverseAndSort(hierarchy)

      console.log('_cards2', _cards, hierarchy, sortNodes)
      if (this.first && hierarchy.length > 0) {
        this.$emit("firstInit", hierarchy);
        this.first = false
      }
      return sortNodes// hierarchy //_cards?.nodes
    },
  },
  data() {
    return {

      chartData: {},
      unwatch: undefined
    };
  },
  mounted() {

    // GoogleCharts.load(drawCharts);

    GoogleCharts.load(drawChart, {
      'packages': ['gantt'],
    });
    function drawChart() {

      function daysToMilliseconds(days) {
        return days * 24 * 60 * 60 * 1000;
      }

      var data = new GoogleCharts.api.visualization.DataTable();
      data.addColumn('string', 'Task ID');
      data.addColumn('string', '任务');
      data.addColumn('date', '开始日期');
      data.addColumn('date', '结束日期');
      data.addColumn('number', 'Duration');
      data.addColumn('number', 'Percent Complete');
      data.addColumn('string', 'Dependencies');

      data.addRows([
        ['Research', '查找文献', new Date(2015, 0, 1), new Date(2015, 0, 5), null, 10, null],
        ['Write', '编写论文', null, new Date(2015, 0, 9), daysToMilliseconds(3), 25, 'Outline'],
        ['Cite', '创建参考目录', null, new Date(2015, 0, 7), daysToMilliseconds(1), 20, 'Research'],
        ['Complete', '交稿', null, new Date(2015, 0, 10), daysToMilliseconds(1), 0, 'Cite,Write'],
        ['Outline', '大纲', null, new Date(2015, 0, 6), daysToMilliseconds(1), 100, 'Research']
      ]);

      var options = {
        height: 275
      };

      var chart = new GoogleCharts.api.visualization.Gantt(document.getElementById('gchart'));

      setTimeout(() => { chart.draw(data, options); }, 500)

    }
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
  /* setup() {
    const nodeStore = useNodeStore()

    function drawChart() {
      const data = GoogleCharts.api.visualization.arrayToDataTable([
        ['City', '2010 Population',],
        ['New York City, NY', 8175000],
        ['Los Angeles, CA', 3792000],
        ['Chicago, IL', 2695000],
        ['Houston, TX', 2099000],
        ['Philadelphia, PA', 1526000]
      ]);

      const options = {
        title: 'Population of Largest U.S. Cities',
        chartArea: { width: '50%' },
        hAxis: {
          title: 'Total Population',
          minValue: 0
        },
        vAxis: {
          title: 'City'
        }
      };

      const chart = new GoogleCharts.api.visualization.BarChart(document.getElementById('gchart'));

      chart.draw(data);
      // const data = GoogleCharts.api.visualization.arrayToDataTable([
      //   ['Chart thing', 'Chart amount'],
      //   ['Lorem ipsum', 60],
      //   ['Dolor sit', 22],
      //   ['Sit amet', 18]
      // ]);
      // const pie_1_chart = new GoogleCharts.api.visualization.PieChart(document.getElementById('gchart'));
      // pie_1_chart.draw(data);
    }
    GoogleCharts.load(drawChart);

    return {
      nodeStore
    }
  } */
};
</script>
<style scoped lang="less"></style>
