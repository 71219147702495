export const getFun = (formModel, inputParams, keyPath) => {
  if (!formModel) return;
  if (keyPath || keyPath === 0) {
    return formModel?.[keyPath];
  }
  if (inputParams.default) {
    formModel[inputParams.id] = inputParams.default;
  }
  const varietyType = inputParams.varietyType;
  const variety = inputParams.variety;
  const prop = inputParams.prop;
  if (varietyType == "tag") {
    if (!formModel?.[varietyType]) {
      formModel[varietyType] = {};
    }

    if (!formModel?.[varietyType]?.[variety]) {
      formModel[varietyType][variety] = {};
    }
    return formModel?.[varietyType]?.[variety]?.[prop];
  } else if (varietyType == "edge") {
    if (!formModel?.props) {
      formModel.props = {};
    }
    return formModel?.props?.[prop];
  }
};
export const setFun = (formModel, inputParams, newValue, keyPath) => {
  if (keyPath || keyPath === 0) {
    return (formModel[keyPath] = newValue);
  }

  const varietyType = inputParams.varietyType;
  const variety = inputParams.variety;
  const prop = inputParams.prop;
  if (varietyType === "tag") {
    formModel[varietyType][variety][prop] = newValue;
  } else if (varietyType === "edge") {
    formModel.props[prop] = newValue;
  }
};
export const getEdge = () => {};
