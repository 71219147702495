<template>
  <Form class="rendered-form" :label-width="labelWidth" :label-position="'left'" :show-message="false">
    <slot></slot>
  </Form>
</template>

<script>
export default {
  props: {
    index: Number,
  },
  inject: ["formModel", "labelWidth", "labelPosition"],
  provide: function () {
    return {
      formModel: this.formModel?.[this.index]

    };
  },
  components: {},
  methods: {

  },
  mounted() { },
};
</script>
<style>
.rendered-form {
  width: 100%;

}
</style>
