<template>
  <div style="display: flex">
    <Button @click="modalShow = true" style="margin-top: 3px">选择</Button>
    <p style="margin-left: 16px" v-html="forigonText"></p>

    <Modal v-model="modalShow" :title="'选择' + inputParams.label" width="760" top="60" draggable sticky scrollable
      :mask="false" @on-ok="assignProperty">


    </Modal>
  </div>
</template>
<script>
import { useDatapoolStore } from '@/stores/datapool';
import { useNodeStore } from '@/stores/node';
import ForeignTable from "../ForeignTable.vue";

export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: {
    formModel: 'formModel',
    getCurNode: 'getCurNode',
    _curNode: {
      from: "_curNode",
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchKey: "",
      filter: {},
      modalShow: false,
      curCard: {},
      isArray: false,
      selectedRow: undefined,
      curLevel: {},
      coordinate: null,
      columns: [
        {
          title: "类型",
          key: "variety",
        },
        {
          title: "名称",
          key: "name",
        },
      ],
      selectNodes: [],
      preText: [],
    };
  },
  components: {
    ForeignTable,
  },
  computed: {
    forigonText: function () {
      console.log("this.formModel()", this.formModel())
      if (this.selectedRow !== undefined) {
        return this.filterValue(this.selectedRow);
      } else {
        if (this.formModel()[this.inputParams.index]?.od) {

          const curModel = this.nodeStore.getNode({
            frameId: this.nodeStore.frame._id,
            coordinate: this.formModel()[this.inputParams.index].od
          })

          console.log("curModel1", curModel)
          // const curModel = this.$store.getters["arr_card/getCardByPosition"]({
          //   frameId: this.$store.state.board.board.position.frameId,
          //   coordinate: this.formModel()[this.inputParams.index].od,
          // });
          return this.filterValue(curModel);
        }
      }
    },
  },
  methods: {
    selected(row) {
      if (!this.isArray) {
        this.selectNodes[0] = row;
        this.selectNodes.splice(0, 1, row);
        this.selectedRow = row;
      } else {
        const has = this.selectNodes.findIndex((n) => {
          return n?.node?._id === row?.node?._id;
        });
        if (has === -1) {
          this.selectNodes.push(row);
        }
        this.selectedRow = this.selectNodes;
      }
    },
    removeRow(index) {
      this.selectNodes.splice(index, 1);
    },
    goUpLevel() {
      const initOd = this.coodinateArr();
      if (initOd.length < this.coordinate.length) {
        this.coordinate = this.coordinate.slice(0, -1);
        this.preText.pop();
        this.$forceUpdate();
      }
    },
    categoryed({ row, value }) {
      console.log(row, value);
      if (row.position.coordinate.length > this.coordinate.length) {
        this.preText.push(value);
      } else if (row.position.coordinate.length == this.coordinate.length) {
        this.preText.splice(-1, 1, value);
      }
      this.coordinate = row.position.coordinate;
    },
    assignProperty() {
      this.curCard = this.selectedRow;
      const newValue = {
        _id: this.curCard?.node?._id,
        od: this.curCard?.position?.coordinate,
      };
      this.formModel()[this.inputParams.index] = newValue;
    },
    coodinateArr() {
      // if (this.inputParams.foreignKeyPreId) {
      //   return this.formModel()[this.inputParams.foreignKeyPreId].coordinate;
      // }
      console.log(
        " this.inputParams?.od",
        this.inputParams?.od,
        this.inputParams
      );
      const odStr = this.inputParams?.od;
      if (!odStr) {
        return null;
      }
      if (typeof odStr === "object") {
        return odStr;
      }
      const level = Number(odStr)
      console.log("level", level)

      if (level < 0) {
        console.log("this._curNode?.position.coordinate", this._curNode?.position.coordinate)
        let _curNodeOd = this._curNode?.position.coordinate
        let preOd = _curNodeOd.slice(0, _curNodeOd.length + level)
        console.log("preOd", preOd)

        return preOd;

      } else if (odStr === "curNode()") {

        //  return this._curNode?.position.coordinate;

      } else {
        return this.inputParams?.od?.split(",")?.map((n) => {
          return parseInt(n);
        });
      }
    },
    filterValue(row) {
      if (!row) {
        return "";
      }
      const valueItem = this.inputParams.valueVariety.find((n) => {
        return n.label === row?.node?.variety;
      });
      if (valueItem) {
        const scheme = this.datapoolStore.getDatapoolByIndex('schemes', row.node.variety, 'variety')

        console.log("scheme.name", scheme, row.node.variety);
        return (
          scheme.name +
          ' : <span class="value-span">' +
          row?.node?.payload[valueItem.value] +
          "</span>"
        );
      }
    },
    search() {
      console.log("search")
      if (!this.searchKey) {
        this.filter = {}
        return
      }
      const filter = {}
      this.inputParams.valueVariety.map((n) => {
        filter[n.value] = this.searchKey
      });

      this.filter = filter
    }
  },
  mounted() {
    let hasValue = this.formModel()[this.inputParams.propertyId];

    if (hasValue) {
      const hasNode = this.nodeStore.getNode({
        frameId: this.nodeStore.frame._id,
        coordinate: this.formModel()[this.inputParams.index].od,
      })
      if (hasNode) {
        this.selectedRow = hasNode;
        this.selectNodes.push(hasNode);
      } else {
        const res = this.nodeStore.initNodeById({
          id: this.formModel()[this.inputParams.index]._id,
          frameId: this.nodeStore.frame._id,
        });
        this.selectNodes.push(res);
        this.selectedRow = hasNode;
      }
    }
    this.coordinate = this.coodinateArr();
    this.filter = this.inputParams.foreignFilter
  },
  setup() {
    const datapoolStore = useDatapoolStore()
    const nodeStore = useNodeStore()
    return {
      nodeStore,
      datapoolStore
    }
  }
};
</script>
<style scoped>
.select-card {
  background: #fbfbfc;
  padding: 4px 8px;
  margin-bottom: 4px;
  position: relative;
}

.remove-btn {
  float: right;
  color: rgb(209 209 209);
}

.remove-btn:hover {
  color: rgb(236, 60, 60);
}

.value-span {
  font-weight: 600;
}

.pre-item {
  border-right: 1px solid #dcdee2;
  padding: 0 4px;
}

.pre-item:last-child {
  border-right: none;
}
</style>
