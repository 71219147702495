<template>
  <div style="padding: 8px;">
    <div v-html="formModelEntity?.[inputParams.prop]" class="grid-item-html"
      style="max-height: 300px;overflow-y: scroll;"></div>
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
  },
  inject: ["_curNode", "preNode"],
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this._curNode?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        let edge = this._curNode.edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })

        return edge?.props
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "../cardGrid.less";
</style>
