<template>
  <div>
    <i-input v-model="modelValue" :show-word-limit="inputParams.maxLength > 0"
      :maxlength="inputParams.maxLength > 0 ? inputParams.maxLength : null" :placeholder="inputParams.placeholder"
      :disabled="inputParams.disabled" :type="inputParams.inputType" :autosize="inputParams.autosize"
      :rows="inputParams.rows" />
  </div>
</template>

<script>
import { getFun, setFun } from '../modelValue.js';
export default {
  props: {
    inputParams: Object,
  },
  inject: ["formModel", "keyPath"],
  //inject: ["_curNode", "preNode"],

  components: {},
  computed: {
    modelValue: {
      get: function () {
        const value = getFun(this.formModel, this.inputParams)
        if (value === null || value === undefined) {
          setFun(this.formModel, this.inputParams, this.inputParams.default)
        }
        return getFun(this.formModel, this.inputParams)
      },
      set: function (newValue) {
        return setFun(this.formModel, this.inputParams, newValue, this?.keyPath)
      },
    },
  },
  // watch: {
  //   modelValue(newValue) {
  //     console.log("newValue", newValue);
  //   },
  // },
  mounted() { },
};
export const isStringType = true;
</script>
