//const files = import.meta.globEager("./*.vue")
const files = import.meta.glob("./*.vue", { eager: true });

let inputs = {};

Object.keys(files).forEach((fileName) => {
  const name = fileName.replace(/\.\/|\.vue/g, "");

  inputs[name] = files[fileName].default;
});
export default inputs;
