<template>
  <div style="    flex: 1;">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
  },
  //inject: ["formModel"],
  provide: function () {
    return {

      keyPath: this.index,

    };
  },
  components: {},
  methods: {

  },
  mounted() { },
};
</script>
<style>
.rendered-form {
  width: 100%;

}
</style>
