<template>
  <!-- <div :style="{ height: inputParams.height ? inputParams.height : '200px' }">
    <MonacoEditor
      class="json-monaco-editor"
      v-model="modelValue"
      language="json"
      ref="json_monacoEditor"
    />
  </div> -->
  <div class="grid-value">{{ modelValue }}</div>
</template>

<script>
import MonacoEditor from "@/components/common/editor/meditor.js";


export default {
  props: {
    inputParams: Object,
  },
  inject: ["_curNode"],
  components: {
    MonacoEditor: MonacoEditor,
  },
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this._curNode?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        if (this.inputParams.variety === this._curNode?.name) {
          const entity = this._curNode?.props;
          return entity
        }
      }
    },
    modelValue: {
      get: function () {
        let value = this.formModelEntity?.[this.inputParams.prop]
        try {
          let json = JSON.parse(value);
          return json
        } catch (e) {
          return value;
        }

      }
    },
  },
};
export const isStringType = true;
</script>
<style lang="less" scoped>
@import "../cardGrid.less";

.json-monaco-editor {
  height: 100%;
  width: 100%;
  height: 200px;
  width: 400px;
}
</style>
