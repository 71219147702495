<template>
  <div>
    <!-- <i-input
      v-model="modelValue"
      :disabled="true"
      :autosize="inputParams.autosize"
    /> -->
    <p>{{ modelValue }}</p>
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
  },
  inject: ["formModel"],
  components: {
  },
  computed: {
    modelValue: {
      get: function () {
        if (this.inputParams.default) {
          this.formModel()[
            this.inputParams.id
          ] = this.inputParams.default;
        }
        // console.log(
        //   "modelValue",
        //   this.inputParams.index,
        //   this.formModel(),
        //   this.formModel()[this.inputParams.index]
        // );
        return this.formModel()[this.inputParams.id];
      },
      set: function (newValue) {
        this.formModel()[this.inputParams.index] = newValue;
      },
    },
  },
  watch: {
    modelValue(newValue) {
      console.log("newValue", newValue);
    },
  },
  mounted() { },
};
export const isStringType = true;
</script>
