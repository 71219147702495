<template>
  <div class="grid-value d-flex" style="align-items: center;">
    <div v-if="fileUrl">
      <div v-if="fileType == 'img'" class="item-pic">
        <img :src="fileUrl" style="max-height: 46px;width: 100%;" />
      </div>
      <i v-else-if="fileType == 'pdf'" class="bi bi-file-pdf file-icon"></i>
      <i v-else-if="fileType == 'video'" class="bi bi-file-earmark-play file-icon"></i>
      <i v-else-if="fileType == 'excel'" class="bi bi-file-earmark-excel file-icon"></i>
      <i v-else-if="fileType == 'word'" class="bi bi-file-earmark-word file-icon"></i>
      <i v-else-if="fileType == 'ppt'" class="bi bi-file-earmark-ppt file-icon"></i>
      <i v-else-if="fileType == 'fold'" class="bi bi-folder2-open file-icon"></i>
      <i v-else class="bi bi-file-earmark file-icon"></i>

    </div>
    <div style="margin-left: 3px;">{{ fileName }}</div>
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
    index: Number
  },
  inject: ["_curNode", "curNode", "preNode"],

  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this.curNode()?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        let edge = this.curNode().edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })
        return edge?.props
      }
    },
    fileUrl() {
      // if (this.index || this.index === 0) {
      //   return this.formModelEntity?.[this.inputParams.id][this.index]
      // }
      return this.formModelEntity?.[this.inputParams.prop]
    },
    fileType: {
      get: function () {
        const url = this.fileUrl
        if (typeof (url) == 'string') {
          if (/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(url)) {
            return "img"
          } else if (/\.(pdf)$/i.test(url)) {
            return "pdf"
          } else if (/\.(mp4|avi|webm)$/i.test(url)) {
            return "video"
          } else if (/\.(xls|xlsx|xlsm|xlsb|csv)$/i.test(url)) {
            return "excel"
          } else if (/\.(doc|docx|docm|dot|dotx|dotm)$/i.test(url)) {
            return "word"
          } else if (/\.(ppt|pptx|pptm|pps|ppsx|ppsm)$/i.test(url)) {
            return "ppt"
          } else if (/\.(ini)$/i.test(url)) {
            return "fold"
          }
        }
      },
    },
    fileName() {
      const url = this.fileUrl
      console.log('url', url)
      if (!url) {
        return ''
      }
      const regex = /[^\/]+$/;
      let match = url.match(regex);
      if (match) {
        return match[0]; // 第一个捕获组中的内容
      } else {
        return url;
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import '../cardGrid.less';

.item-pic {
  width: 64px;
  flex: 0 0 64px;
  text-align: center;
}

.file-icon {
  line-height: 28px;
  font-size: 20px;
}
</style>
