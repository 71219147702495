<template>
  <div class="main">
    <div class="left-sider">
      <div class="logo-con">
        <img :src="minLogo" key="min-logo" />
      </div>
      <div class="menu-list">
        <div :class="['menu-item', activeJudge(item)]" v-for="(item, i) in menuList" :key="i" @click="toPage(item)">
          <div class="icon-wrap">
            <i :class="['bi', item.icon]"></i>
          </div>
          <div class="menu-item-title">
            {{ item.title }}
          </div>
        </div>
      </div>
      <Poptip placement="right-end" style="text-align: center;width: 100%;position: absolute;bottom: 40px;">
        <div>
          <Avatar :src="user.avatar" size="large" style="border: 2px solid #e0e1e7" />
          <div class="user-name">{{ user.username }}</div>
        </div>
        <template #content>
          <div class="user-menu-list">
            <div class="list-item">个人中心</div>
            <div class="list-item">设置</div>
            <div class="list-item" @click="logout">退出</div>
          </div>
        </template>
      </Poptip>
    </div>
    <div class="main-content">
      <router-view />
    </div>
  </div>
</template>
<script>
import { logout } from '@/api/user';
import minLogo from '@/assets/images/gearblade_logo.png';
import { useUserStore } from '@/stores/user';

export default {
  name: 'Main',
  components: {
  },
  data() {
    return {
      minLogo,
      iframeWin: null,
      iframes: [],
      tagNavList: [],
      menuList: [
        {
          title: '主板',
          // url: 'ssrUrl',
          icon: 'bi-map-fill',
          url: "/home",
          names: ['home']
        },
        {
          title: '工作区',
          icon: 'bi-grid-1x2-fill',
          url: "/frame/list",
          names: ['frameList']
        },
        // {
        //   title: "信件",
        //   icon: "bi-envelope-open-fill",
        // },
        {
          title: '通讯',
          icon: 'bi-chat-square-dots-fill',
        },
        // {
        //   title: "个人",
        //   icon: "bi-person-fill",
        // },
        // {
        //   title: '设置',
        //   icon: "bi-gear-fill",
        // },
        {
          title: '扩展',
          icon: 'bi-cloud-plus-fill',
        },
        {
          title: '帮助',
          icon: 'bi-question-circle-fill',
        },
      ],
      user: {}
    }
  },
  computed: {},
  methods: {
    activeJudge(item) {
      const routerName = this.$route.name
      if (item?.names?.includes(routerName)) {
        return 'active'
      }
      console.log('routerName', routerName)
    },
    toPage(item) {
      this.$router.push(item.url)
    },
    logout() {
      // this.localstorage.clear()
      this.userStore.clearUser()
      localStorage.clear();
      logout()
      this.$router.push({ name: "login" })
    }
  },
  watch: {},
  mounted() {
    this.user = { ...this.userStore }
  },
  setup() {
    const userStore = useUserStore()
    return {
      userStore
    }
  }
}
</script>
<style lang="less" scoped>
@import url('~bootstrap-icons/font/bootstrap-icons.css');
@import "@/styles/index.less";
// @primary-color: #1b3d6b;

.main {
  display: flex;
  height: 100vh;

  .left-sider {
    width: 58px;
    flex: 0 0 58px;
    border-right: 1px solid #e9ebef;
    background: #fff;
    height: 100%;
    position: relative;

    .menu-list {
      text-align: center;

      // box-shadow: 1px 0px 0px #e6e9ec;
      .menu-item {
        padding: 12px 7px 8px;

        // border-bottom: 1px solid #e8eaef;
        &.active {
          background-color: #e9ebef;
        }

        &:hover {
          background-color: #e9ebefd7;
        }

        .icon-wrap {
          color: @primary-color;
          font-size: 14px;
        }

        .menu-item-title {
          text-align: center;
          font-size: 12px;
          margin-top: 0;
          line-height: 20px;
          color: rgba(27, 61, 106, 0.7);
        }
      }

    }

    .user-name {
      font-size: 12px;
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .logo-con {
    height: auto;
    padding: 10px;

    img {
      height: 38px;
      width: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .main-content {
    width: 100%;
    background-color: #cdcdcd24;
  }

  .user-menu-list {

    .list-item {
      padding: 8px;
      border-bottom: 1px solid #ddd;
      text-align: left;
    }

    .list-item:last-child {
      border-bottom: none;

    }
  }
}
</style>
