<template>
  <div>
    <Transition name="fade" mode="out-in">
      <div v-if="!updateMode" style="width: 100%;" class="rendered-grid eo-row" :class="{
        'grid-size': 'grid-' + size
      }">
        <slot></slot>
        <Button v-if="updateScene && updatable" type="info" class="update-btn " ghost size="small" @click="checkMode">
          {{ submitBtn.text }}
        </Button>
      </div>
      <div v-else style="padding: 12px;" class="rendered-grid eo-row">
        <FromPlotRender :scene="updateScene" :params="{ formModel: { tag: getCurNode().tag, edge: _curEdges } }"
          :key="id" :type="'plot'">
        </FromPlotRender>
        <i-button class="cancel-btn" size="small" @click="checkMode">
          取消
        </i-button>
      </div>
    </Transition>
  </div>
</template>
<script>
import FromPlotRender from "@/components/plotRender/FromPlotRender.js";
import { useNodeStore } from '@/stores/node';
import { reactive } from 'vue';
import RenderedGridItem from "./RenderedGridItem";

export default {
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  },
  components: {
    RenderedGridItem,
    FromPlotRender
  },
  props: {
    labelPosition: {
      type: String,
      default: "left",
    },
    labelWidth: {
      type: String,
      default: "80px"
    },
    updateForm: Object,
    submitBtn: {
      type: Object,
      default: () => {
        return {
          text: "修改",
          type: "primary",
          style: {},
        };
      },
    },
    type: {
      type: String,
    },
    id: String,
    preId: String,
    updateScene: {
      type: String
    },
    size: String,
    dense: String,
    updatable: {
      type: Boolean,
      default: true
    },
  },
  inject: ["_curNode", "preNode"],
  data() {
    return {
      show: false,
      updateMode: false,
      idTrick: false,
      _curNodeT: reactive({})
    };
  },
  provide: function () {
    return {
      labelPosition: this.labelPosition,
      labelWidth: this.labelWidth,
      size: 'size',
      dense: 'dense',
      preNode: this._preNode,
      _curNode: this._curNodeT,
      curEdges: this._curEdges,
      curNode: () => this.__curNode
    };
  },
  computed: {
    __curNode() {
      if (this.id) {
        console.log("__curNode", this.id)
        Object.assign(this._curNodeT, this.nodeStore.getNodeById(this.id))
        // this._curNodeT = this.nodeStore.getNodeById(this.id)
        return this._curNodeT
      } else {
        Object.assign(this._curNodeT, this._curNode)

        return this._curNodeT
      }
    },
    _preNode() {
      if (this.preId) {
        return this.nodeStore.getNodeById(this.preId)
      } else {
        return this.preNode
      }
    },
    _curEdges() {
      let edges = this.__curNode?.edge?.filter(e => {
        return e.src === this._preNode?.vid && e.dst === this.__curNode?.vid
      })
      return edges
    },
  },
  watch: {
    id(val) {
      this.idTrick = true
      this.idTrick = false
    }
  },
  methods: {
    checkMode() {
      this.updateMode = !this.updateMode;
    },
    reset() {
      this.$refs.iForm.resetFields();
    },
    getCurNode() {
      if (this.id) {
        return this.nodeStore.getNodeById(this.id)
      } else {
        return this._curNode
      }
    },
    // getCurEdge() {
    //   if (this.id) {
    //     return this.nodeStore.getNodeById(this.id)
    //   } else {
    //     return this._curNode
    //   }
    // }
  },
};
</script>

<style lang="less">
@import "@/styles/simple-grid.less";
@import './cardGrid.less';
</style>
