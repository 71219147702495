<template>
  <div v-if="inputParams" :style="{
    position: 'relative',
  }">
    <Button @click="targleEditor" v-if="!showEditor">编辑</Button>
    <MonacoEditor v-if="showEditor" class="json-monaco-editor" :value="modelValue"
      @change="modelValue = $event?.target?.value" language="json" :options="{
        automaticLayout: true,
        foldingStrategy: 'indentation',
      }" ref="json_monacoEditor" />
  </div>
</template>

<script>
import MonacoEditor from "@/components/common/editor/meditor.js";
import { getFun, setFun } from '../modelValue.js';

export default {
  props: {
    inputParams: Object,
  },
  inject: ["formModel", "keyPath"],
  data() {
    return {
      showEditor: false,
      height: '300px'
    };
  },
  methods: {
    targleEditor() {
      this.height = "300px"
      this.showEditor = true
    }
  },
  components: {
    MonacoEditor
  },
  computed: {
    modelValue: {
      get: function () {
        return getFun(this.formModel, this.inputParams, this?.keyPath)
      },
      set: function (newValue) {
        console.log('modelValue1', newValue, typeof (newValue))
        console.log('this.formModel', this.formModel)
        let str = newValue
        setFun(this.formModel, this.inputParams, str, this?.keyPath)
        console.log('this.formModel2', this.formModel)

      },
    },
    contentStr: {
      get: function () {
        if (this.inputParams.default) {
          this.formModel()[
            this.inputParams.propertyId
          ] = this.inputParams.default;
        }
        let value = this.formModel()[this.inputParams.propertyId];
        let json = JSON.stringify(value, null, 2);

        return json ? json : "";
      },
      set: function (newValue) {
        if (typeof (newValue) == 'string') {
          this.formModel()[this.inputParams.index] = JSON.parse(newValue);

        }
        console.log("newValue12", newValue)
      },
    },
  },
  watch: {
    // modelValue(newValue) {
    //   console.log("newValue", newValue);
    // },
  },

  mounted() { },
};
export const isStringType = true;
</script>
<style lang="less" scoped>
.json-monaco-editor {
  height: 100%;
  width: 100%;
  min-height: 300px;
  // min-width: 100px;
}
</style>
