<template>
  <div>
    <template v-if="item.useDropdown">
      <Select v-model="form[item.id]" :multiple="item.multi" :disabled="disabled">
        <Option v-for="sel in item.selectionItems" :value="sel" :key="sel">
          {{ sel }}
        </Option>
      </Select>
    </template>
    <template v-else-if="item.multi">
      <CheckboxGroup v-model="form[item.id]" :disabled="disabled">
        <Checkbox v-for="sel in item.selectionItems" :key="sel" :label="sel"></Checkbox>
      </CheckboxGroup>
    </template>
    <template v-else>
      <RadioGroup v-if="!disabled" v-model="form[item.id]">
        <Radio v-for="sel in item.selectionItems" :key="sel" :label="sel"></Radio>
      </RadioGroup>
      <i-input v-else v-model="form[item.id]" :disabled="disabled" />
    </template>
  </div>
</template>

<script>
// import {
//   Checkbox,
//   CheckboxGroup,
//   Option,
//   Radio,
//   RadioGroup,
//   Select,
// } from "view-ui-plus";
export default {
  // components: {
  //   RadioGroup,
  //   Radio,
  //   CheckboxGroup,
  //   Checkbox,
  //   Select,
  //   Option,
  // },
  props: {
    item: Object,
    form: Object,
    disabled: Boolean,

  },
};
export function initItem(input) {
  input.multi = false;
  input.useDropdown = false;
  input.selectionItems = ["选项1", "选项2", "选项3"];
  return input;
}
</script>

<style lang="less" scoped></style>
