<template>
  <div>
    <Modal v-model="createCardModalShow" v-bind="modalParams.props" footer-hide>
      <div>
        <FileForm :preId="preId" :provider="provider" @save-card="saveCard" @close="createCardModalShow = false"
          :fileFilter="fileFilter" :id="id" :directory="directory" />
      </div>
    </Modal>
    <Button v-bind="btnParams.props" class="re-small" :style="btnParams.style" v-on:click="createCardModalShow = true">
      <slot></slot>
      <i class="bi bi-upload" style="font-size: 10px"></i>
      {{ title ? title : "上传" }}
    </Button>
  </div>
</template>
<script>
import { useNodeStore } from '@/stores/node';
import FileForm from "./FileForm.vue";

export default {
  props: {
    id: String,
    form: Object,
    title: String,
    coordinate: Array,
    provider: String,
    directory: Boolean,
    fileFilter: {
      type: Array,
    },
    btnParams: {
      type: Object,
      default: function () {
        return {
          props: {
            long: true,
            type: "default",
            size: "default",
          },
        };
      },
    },
    modalParams: {
      type: Object,
      default: function () {
        return {
          props: {
            styles: {
              top: "60px",
              width: "720px",
            },
            title: "上传图片",
          },
        };
      },
    },
  },
  components: {
    FileForm,
  },
  inject: ["curContainer"],

  data() {
    return {
      buttonProps: {
        type: "default",
        size: "small",
      },
      defaultParams: {},
      cardTypeIndex: 0,
      createCardModalShow: false,
      typeSelectModal: false, // 选择卡片类型弹窗
      createCardModal: false, // 创建卡片弹窗
      cardTypes: [],
    };
  },
  computed: {
    preId: function () {
      if (this.coordinate) {
        const curContainer = this.nodeStore.getNode({
          coordinate: this.coordinate,
          frameId: this.nodeStore.frame._id,
        });
        return curContainer?.node?._id;
      } else {
        return this.curContainer()?.node?._id;
      }
    },
  },
  methods: {
    saveCard(card) {
      console.log("card1", card);
      console.log('modalParams', this.modalParams)
      this.$emit("saveCard", card);
    },
  },
  provide: function () {
    return {
      // curContainer: () => {
      //   return this.$store.getters['arr_card/getCardByPosition']({ coordinate: this.coordinate, frameId: this.$store.state.board.board.position.frameId })
      // }
    };
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less">
.item-label {
  padding: 8px;
}
</style>
