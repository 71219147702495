import { useDatapoolStore } from "@/stores/datapool";
import { useNodeStore } from "@/stores/node";
import { useUserStore } from "@/stores/user";
import "@/styles/board.less";
import "@/styles/index.less";
import _ from "lodash-es";

import axios from "axios";
import cloneDeep from "lodash-es/cloneDeep";
import { Message } from "view-ui-plus";
import { computed, h, onMounted, provide, ref } from "vue";
import { evalExpression, mapAssign, plotRender } from "./render.js";

let FromPlotRender;

FromPlotRender = {
  name: "FromPlotRender",
  props: {
    plot: {
      type: Object,
    },
    scene: {
      type: String,
    },
    params: {
      type: Object,
    },
    datapool: {
      type: String,
      default: "forms",
    },
  },
  inject: ["_curNode"],
  provide: function () {
    return {
      // formModel: computed(() => {
      //   const formModel = cloneDeep(this.params.formModel);
      //   return reactive(formModel);
      // }),

      // preId: this.preId,
      // form: this.form?undefined:this.form,
      _curNode: computed(() => {
        if (this?.params?._curNode) {
          return this?.params?._curNode;
        } else {
          return this._curNode;
        }
      }),
    };
  },
  setup(props) {
    const datapoolStore = useDatapoolStore();
    const nodeStore = useNodeStore();
    const userStore = useUserStore();

    //获取视图
    let plot = props?.plot;
    const scene = props?.scene;
    if (scene) {
      plot = datapoolStore.getDatapoolByIndex("forms", scene, "scene");
    }
    //设定 传参内部变量
    //let params = ref(props?.params)
    let params = props?.params;
    let preNodes = props?.preNodes;
    if (params?._curNode) {
      preNodes?.push(params._curNode);
    }
    const formModelValue = ref(cloneDeep(params.formModel));

    provide("formModel", formModelValue);
    // let paramsRefs ={}
    // for(let index in params){
    //   paramsRefs[index] = ref(params[index])
    // }

    //设定 视图内部变量
    const plotProperty = plot?.property;
    const plotParams = {};
    if (plotProperty) {
      plotProperty.map((n) => {
        plotParams[n.key] = ref(n.value);
        // plotParams[n.key] = n.value
      });
    }
    // const nodeStore = useNodeStore()
    const contextFunctions = {
      curNode: () => {
        return params?._curNode;
      },
    };

    const context = ref({
      $states: datapoolStore.states, //设定 全局状态变量
      $branch: nodeStore.branch, //设定 全局状态变量
      $frame: nodeStore.frame,
      userNode: nodeStore.getUserNode(userStore._id),
      preNodes: preNodes,
      ...params,
      ...plotParams,
      ...contextFunctions, //设定 内部方法
    });
    if (params?._curNode) {
      //  provide('_curNode',  params._curNode)
    }
    const assignParams = (key, value) => {
      const assignValue = evalExpression(value, context.value);
      const assignkey = evalExpression(key, context.value);

      console.log("assignParams", context);
      console.log("assignValue", assignValue);
      context.value[assignkey] = assignValue;
    };
    const stateHandler = (key, value) => {
      const stateValue = evalExpression(value, context.value);
      const state = evalExpression(key, context.value);
      console.log("stateHandler", stateValue, state);

      datapoolStore.states[state] = stateValue;
      console.log("datapoolStore", datapoolStore.states);
    };
    const requestHandler = async (
      { handler, params = {}, branch, partial },
      eventProps
    ) => {
      console.log(
        "requestHandler",
        handler,
        params,
        branch,
        partial,
        eventProps
      );
      try {
        // let branch = {
        //   default: ["branch1", "branch2"],
        // };
        // let partial = {
        //   default: ["list1", "list2"],
        // };
        let postParams = {
          ...params,
          ...eventProps,
          frameId: nodeStore.frame._id,
        };
        // params.frameId = nodeStore.frame._id;
        const response = await axios.post(handler, postParams);
        Object.assign(context.value, response?.data?.context);

        // nodeStore.initNode(response?.data?.nodes)
        // response?.data?.ways?.map((way) => {
        //   return this.initWay(way);
        // });
        let res = nodeStore.initWays(response?.data?.ways, branch);
        //context.value[]
        Message.info(response?.data?.msg);
        //  data.value = response.data;
      } catch (error) {
        Message.error("请求数据失败");

        console.error("请求数据失败:", error);
      }
    };
    //事件行为队列执行器
    const actionsDeal = (event, context, eventProps) => {
      event.actions.map((action) => {
        const actionObj = mapAssign(action, context);
        switch (action.action) {
          case "stateHandler":
            stateHandler(actionObj.key, actionObj.value);
            break;
          case "assignParams":
            assignParams(actionObj.key, actionObj.value);
            break;
          case "request":
            requestHandler(actionObj, eventProps);
            break;
        }
      });
    };

    //事件处理
    const eventDeal = (node) => {
      // console.log("eventDeal", node);

      const nodeEvents = node.events;
      let eventObj = {};
      nodeEvents.map((ev) => {
        let eventName = "on" + ev.event;
        //  console.log("eventName", eventName);

        let fun = ($event) => {
          context.value["$event"] = _.assign({}, $event);
          console.log("context1-1", context, $event);
          console.log("ev", ev);
          actionsDeal(ev, context, $event);
          /* ev.actions.map((action) => {
            const actionObj = mapAssign(action, context);
            switch (action.action) {
              case "stateHandler":
                stateHandler(actionObj.key, actionObj.value);
                break;
              case "assignParams":
                assignParams(actionObj.key, actionObj.value);
                break;
              case "requestHandler":
                requestHandler(actionObj.handler, actionObj.params);
                break;
            }
          }); */
          console.log("context2", context);
        };
        eventObj[eventName] = fun;
      });
      // console.log("eventObj",eventObj)
      return eventObj;
    };
    let contextValue = _.clone(context.value);
    onMounted(() => {
      let node = plot?.viewStruct;
      if (node?.events) {
        const mountedEvent = node?.events?.find((n) => {
          return n.event === "mounted";
        });
        // console.log("mountedEvent", mountedEvent);
        if (mountedEvent) {
          actionsDeal(mountedEvent, context);
        }
      }
    });
    return () =>
      plotRender(h, plot?.viewStruct, { context: context, eventDeal });
  },
};
export default FromPlotRender;
