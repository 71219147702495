<template>
  <div>
    <Button type="info" class="my-btn-normal" @click="modalCreate = true">
      邀请人员
    </Button>
    <Modal v-model="modalCreate" :title="`邀请人员 ：` + dept?.tag?.dept?.name" width="410" footer-hide>
      <div class="invate-div">
        <Button type="default" :loading="loading" @click="inviteUser()" style="margin-right: 16px">
          邀请链接
        </Button>
        <a target="_blank" :href="'/invite/' + inviteCode" style="font-size: 12px; line-height: 28px">
          {{ inviteCode ? inviteUrl + inviteCode : "" }}
        </a>
      </div>
    </Modal>
  </div>
</template>
<script>
import { createInvite } from '@/api/frame';
import { useNodeStore } from '@/stores/node';

export default {
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore,
    }
  },
  props: ['dept'],
  data() {
    return {
      inviteCode: '',
      inviteUrl: window.location.host + '/invite/',
      modalCreate: false,
      loading: false,
      user: undefined,
      selectedUser: []
    }
  },
  methods: {
    inviteUser() {
      this.loading = true
      createInvite({
        deptId: this.dept.vid,
        frameId: this.nodeStore.frame._id
      }).then((res) => {
        this.loading = false

        console.log('inviteCode', res)
        this.inviteCode = res.data.data.code
      }).catch(error => {
        this.loading = false
      })
    }
  },
  computed: {},
  watch: {},
  mounted() { }
}
</script>
<style scoped>
.invate-div {
  display: flex;
  margin-bottom: 16px;
  margin-top: 8px;
}

.user-list-tab {
  padding: 8px 12px;
}
</style>