<template>
  <div>
    <FormItem label="可多选">
      <Checkbox v-model="item.multi" :disabled="disabled"></Checkbox>
    </FormItem>
    <FormItem label="使用下拉选择">
      <Checkbox v-model="item.useDropdown" :disabled="disabled"></Checkbox>
    </FormItem>
    <FormItem label="选项（一行一个）">
      <i-input type="textarea" v-model="selectionItems" :rows="5" :disabled="disabled" />
    </FormItem>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    item: Object, disabled: Boolean
  },
  computed: {
    selectionItems: {
      get() {
        let orig = this.item.selectionItems;
        return orig.join("\n");
      },
      set(value) {
        this.item.selectionItems = value.trim().split("\n");
      }
    }
  }
};
</script>
