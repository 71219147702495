<template>
  <div class="table-text__container">
    <div class="table-text__row">
      <div class="table-text__col table-header-item" v-for="colName in item.tableCols" :key="colName">
        {{ colName }}
      </div>
    </div>

    <div v-for="(row, index) in tableContent" :key="index" class="table-text__row">
      <div class="table-text__col" v-for="colName in item.tableCols" :key="colName">
        <Input v-model="row[colName]" @input="checkAddRow(index)" :disabled="disabled" />
      </div>
    </div>
  </div>
</template>

<script>
//import { Button, Input } from "view-ui-plus";
export default {
  //  components: { "i-input": Input, Button },
  props: {
    item: Object,
    form: Object, disabled: Boolean,
  },
  computed: {
    tableContent: {
      get() {
        if (!this.form[this.item.id]) {
          this.$set(this.form, this.item.id, [{}]);
        }
        return this.form[this.item.id];
      },
      set(v) {
        this.$set(this.form, this.item.id, v);
      },
    },
  },
  methods: {
    checkAddRow(index) {
      if (index === this.tableContent.length - 1) {
        this.tableContent.push({});
      }
    }
  },
};
export function initItem(input) {
  input.tableCols = ["列1", "列2", "列3"];
  return input;
}
</script>

<style lang="less">
.table-text__container {
  width: 100%;
}

.table-text__row {
  display: flex;
  flex-direction: row;
}

.table-text__col {
  flex-grow: 1;
  flex-basis: 80px;
  margin-right: 12px;
}

.table-text__col:last-child {
  margin-right: 0;
}

.table-header-item {
  padding-left: 7px;
}
</style>
