<template>

  <slot></slot>
</template>

<script>
export default {
  props: {
    index: Number,
  },
  inject: ["_curNode",],
  provide: function () {
    return {
      _curNode: this._curNode?.[this.index],
    };
  },
  components: {},
  methods: {

  },
  mounted() { },
};
</script>
<style>
.rendered-form {
  width: 100%;

}
</style>
