<template>
  <div>
    <FormItem label="最大值">
      <InputNumber v-model="item.max"></InputNumber>
    </FormItem>
    <FormItem label="最小值">
      <InputNumber v-model="item.min"></InputNumber>
    </FormItem>
  </div>
</template>

<script>

export default {
  components: {

  },
  props: {
    item: Object,
    disabled: Boolean,
  },
};
</script>
