<template>
  <div>
    <FormItem label="列名（一行一个）">
      <i-input type="textarea" v-model="tableCols" :rows="5" :disabled="disabled" />
    </FormItem>
  </div>
</template>

<script>
export default {
  components: {
    // Checkbox,

  },
  props: {
    item: Object, disabled: Boolean,
  },
  computed: {
    tableCols: {
      get() {
        let orig = this.item.tableCols;
        return orig.join("\n");
      },
      set(value) {
        this.item.tableCols = value.trim().split("\n");
      },
    },
  },
};
</script>

<style lang="less" scoped></style>
