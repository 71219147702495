<template>
  <div style="display:flex;gap:16px">
    <div class="item-pic" v-if="modelValue">
      <img v-if="fileType == 'img'" :src="modelValue" />
      <div v-else-if="fileType == 'pdf'" class="blank-file bg-pdf">
        <i class="bi bi-file-pdf file-icon"></i>
      </div>
      <div v-else-if="fileType == 'video'" class="blank-file ">
        <i class="bi bi-file-earmark-play file-icon"></i>
      </div>
      <div v-else-if="fileType == 'excel'" class="blank-file ">
        <i class="bi bi-file-earmark-excel file-icon"></i>
      </div>
      <div v-else-if="fileType == 'word'" class="blank-file ">
        <i class="bi bi-file-earmark-word file-icon"></i>
      </div>
      <div v-else-if="fileType == 'ppt'" class="blank-file ">
        <i class="bi bi-file-earmark-ppt file-icon"></i>
      </div>
      <div v-else-if="fileType == 'fold'" class="blank-file ">
        <i class="bi bi-folder2-open file-icon"></i>
      </div>
      <div v-else class="blank-file  ">
        <i class="bi bi-file-earmark file-icon"></i>
      </div>
    </div>
    <div v-else class="blank-file"></div>
    <div class="item-input">
      <i-input v-model="modelValue" />
      <FileCardCreate :coordinate="[3]" :title="'上传'" :provider="inputParams.provider" @saveCard="saveCard"
        :directory="inputParams.flod" :fileFilter="inputParams.fileFilter" :id="inputParams.id"
        style="margin-top:12px;width:120px" />
    </div>
  </div>
</template>

<script>
import FileCardCreate from "@/components/plot/FileCardCreate";
import { getFun, setFun } from '../modelValue.js';

export default {
  props: {
    inputParams: Object
  },
  inject: ["formModel", "keyPath"],
  components: {
    FileCardCreate,
  },
  methods: {
    saveCard(card) {
      if (!this.inputParams.islocalUrl) {
        this.modelValue = card;
      } else {
        // this.modelValue = card.node.payload.url;

      }
    },
  },
  computed: {
    modelValue: {
      get: function () {
        return getFun(this.formModel, this.inputParams, this.keyPath)
      },
      set: function (newValue) {
        return setFun(this.formModel, this.inputParams, newValue, this.keyPath)
      },
    },
    fileType: {
      get: function () {
        const url = this.modelValue
        if (typeof (url) == 'string') {
          if (/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(url)) {
            return "img"
          } else if (/\.(pdf)$/i.test(url)) {
            return "pdf"
          } else if (/\.(mp4|avi|webm)$/i.test(url)) {
            return "video"
          } else if (/\.(xls|xlsx|xlsm|xlsb|csv)$/i.test(url)) {
            return "excel"
          } else if (/\.(doc|docx|docm|dot|dotx|dotm)$/i.test(url)) {
            return "word"
          } else if (/\.(ppt|pptx|pptm|pps|ppsx|ppsm)$/i.test(url)) {
            return "ppt"
          } else if (/\.(ini)$/i.test(url)) {
            return "fold"
          }
        }
      },
    }
  },
  mounted() {
    if (this.inputParams.default) {
      this.formModel()[this.inputParams.id] = this.inputParams.default;
    }
  },
};
export const isStringType = true;
</script>
<style scoped lang="less">
.item-pic {
  width: 76px;
  height: 76px;
  flex: 0 0 76px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.blank-file {
  width: 76px;
  height: 76px;
  // background: #ddd;
  background: #f7f7f7;
  text-align: center;
  border-radius: 5px;

  .file-icon {
    line-height: 76px;
    font-size: 40px;
  }
}

.bg-pdf {
  background: #ffffff;
  color: #223c5e;
}

.item-input {
  flex: 1 1 auto;
}
</style>
