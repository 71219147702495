import axios from "axios";

export function login({ tel, code }) {
  const data = {
    tel,
    code,
  };
  return axios.post("/auth/loginSns", data);
}
export function logout() {
  return axios.post("/auth/logout");
}
export function loginPwd({ tel, password }) {
  const data = {
    tel,
    password,
  };
  return axios.post("/auth/login", data);
}
export const signin = ({ tel, username, password, code }) => {
  const data = {
    tel,
    username,
    password,
    code,
  };
  return axios.request({
    url: "/auth/user",
    data,
    method: "put",
  });
};

export function telVerify({ tel }) {
  return axios.request({
    url: "https://eo.enginorigin.com/api/user/user/tel-verify",
    data: {
      tel,
    },
    method: "post",
  });
}
export function getUserBase(id) {
  return axios.request({
    url: `/user/userBase/${id}`,
    method: "get",
  });
}

export function getProfile(id) {
  return axios.request({
    url: "/api/user/profile/view",
    params: {
      id,
    },
    method: "get",
  });
}
export function getUserInfo(token) {
  return axios.request({
    url: "/v1/user/profile",
    params: {
      // access_token: window.localStorage.getItem('access_token')
    },
    method: "get",
  });
}
export const findUser = (query) =>
  axios.post("/v1/user/user", {
    query,
  });

export const updateBase = (data) =>
  axios.request({
    url: "/auth/user",
    data,
    method: "patch",
  });
export const deleteUser = ({ id }) =>
  axios.request({
    url: `/v1/user/user/${id}`,
    //  params: { id },
    method: "delete",
  });
