<template>
  <div>
    <FormItem label="选项类型">
      <Select v-model="item.innerType" :disabled="disabled">
        <Option v-for="sel in typeList" :value="sel.value" :key="sel.value">
          {{ sel.label }}
        </Option>
      </Select>
    </FormItem>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      typeList: [
        {
          value: "date",
          label: "单个日期",
        },
        {
          value: "daterange",
          label: "日期范围",
        },
        {
          value: "datetime",
          label: "单个日期时间",
        },
        {
          value: "datetimerange",
          label: "日期时间范围",
        },
        {
          value: "time",
          label: "单个时间",
        },
        {
          value: "timerange",
          label: "时间范围",
        },
      ],
    };
  },
  props: {
    item: Object,
    disabled: Boolean,
  },
};
</script>
