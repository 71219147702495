<template>
  <div id="example-gantt"></div>
  <!-- <div v-for="(item, i) in branchNodes?.nodes">{{ i }}</div> -->
</template>
<script>
import { useNodeStore } from '@/stores/node';
import _ from "lodash-es";
import { SvelteGantt } from 'svelte-gantt';

export default {
  props: {
    options: Object,
    id: String,
    branch: String,
    code: String,
    mountedWatch: Object,
  },
  computed: {
    branchNodes() {
      const nodes = this.nodeStore.getBranch({
        branchId: 'lab_node-L',
      })
      console.log('branchNodes0', nodes)
      // if (this.code) {
      //   this.evalCode(this.code, { gantt: this.gantt, branchNodes: nodes })
      // }
      return nodes
    }
  },
  data() {
    return {
      gantt: {},
      chartData: {},
      unwatch: undefined,
      loading: false
    };
  },
  mounted() {
    // 初始化图表实例
    console.log(document.getElementById(this.id))

    const options = {
      rows: [
        {
          id: 0,
        },
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        }
      ],
      tasks: [

      ],
      columnOffset: 15,
      magnetOffset: 15,
      rowHeight: 52,
      rowPadding: 6,
      headers: [{ unit: 'day', format: 'YYYY-MM-DD' }, { unit: 'hour', format: 'H:mm', offset: 2 }],
      fitWidth: true,
      minWidth: 800,
      from: 1721664000000,
      to: 1721836800000,
      tableHeaders: [{ title: 'aaa', property: 'label', width: 140, type: 'tree' }],
      tableWidth: 0,
      enableCreateTask: true,
    };
    this.gantt = new SvelteGantt({
      target: document.getElementById('example-gantt'),
      props: options
    });
    if (this.code) {
      console.log('mounted')


      // this.gantt.$set({
      //   tasks: [
      //     {
      //       id: 1,
      //       resourceId: 1,
      //       amountDone: 25,
      //       from: 1721689200000,
      //       to: 1721782800000,
      //       label: '任务2',
      //     }
      //   ],
      // })
      // this.evalCode(this.code, { gantt: this.gantt, branchNodes: this.branchNodes })
    }
  },
  watch: {
    mountedWatch: {
      deep: true,
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (Array.isArray(newVal)) {
          for (let i = 0; i < newVal.length; i++) {
            if (!_.isEqual(newVal[i], oldVal?.[i])) {
              this.fetchContext(1, 0);
              console.log('list mountedWatch', newVal, oldVal)
              break
            }
          }
        } else {
          if (!_.isEqual(newVal, oldVal)) {
            this.fetchContext(1, 0);
            console.log('mountedWatch list2', newVal, oldVal)
          }
        }

      }
    },
    branchNodes: {
      deep: true,
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (this.code) {
          this.evalCode(this.code, { gantt: this.gantt, branchNodes: newVal })
        }
      }
    },
  },
  methods: {
    async fetchContext(current = 1, step = 0) {
      try {
        this.loading = true;
        this.$emit("mounted", {});
      } catch {
        (error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
        }
      } finally {
        this.loading = false;
      }
    },
    evalCode(code, context) {
      // const regex = /^`(.*)`$/;
      //  const match = code.match(regex);
      const restrictedEval = new Function("$", code);
      //  console.log("this.code2", this.code)

      return restrictedEval(context);

    }
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less"></style>
