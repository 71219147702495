<template>
  <div class="card-view">
    <div class="cr-cell mt-10">
      <Button class="my-btn-normal" @click="modalCreate = true">新增权限</Button>
    </div>
    <div class="mt-10 mb-10 border" style="border-bottom: 0;">
      <Table :columns="columns" :data="abilityList" height="520" @on-row-click="selectPolicy" size="small">
        <template #num="{ row, index }">
          <div style="padding: 8px;">{{ index }}</div>
        </template>
        <template #subject="{ row }">
          <div style="padding:0 8px">
            <div v-if="row.subject.start"><span class="policy-label">start： </span><span>{{ row.subject.start }}</span>
            </div>
            <div v-if="row.subject.end && row.subject.end.length != 0"><span class="policy-label">end：</span>
              <span>{{ row.subject.end }}</span>
            </div>
            <div class="d-flex">
              <div v-if="row.subject.method" class="mr-5"><span class="policy-label">method：</span>
                <span>{{ row.subject.method }}</span>
              </div>
              <div v-if="row.subject.downlevel"><span class="policy-label">downlevel： </span>
                <span>{{ row.subject.downlevel }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #object="{ row }">
          <div style="padding:0 8px">
            <div v-if="row.object.start"><span class="policy-label">start： </span><span>{{ row.object.start }}</span>
            </div>
            <div v-if="row.object.end && row.object.end.length != 0"><span class="policy-label">end： </span>
              <span>{{ row.object.end }}</span>
            </div>
            <div class="d-flex">
              <div v-if="row.object.method" class="mr-5"><span class="policy-label">method： </span>
                <span>{{ row.object.method }}</span>
              </div>
              <div v-if="row.object.downlevel"><span class="policy-label">downlevel： </span>
                <span>{{ row.object.downlevel }}</span>
              </div>
            </div>
          </div>
        </template>
      </Table>
    </div>
    <Modal v-model="modalCreate" title="添加权限" width="720">
      <Form :model="formPolicy" label-position="left" :label-width="160" class="p-2">
        <FormItem label="行为action">
          <Input v-model="formPolicy.action"></Input>
        </FormItem>
        <FormItem label="正负positive">
          <InputNumber v-model="formPolicy.positive"></InputNumber>
        </FormItem>
        <FormItem label="行为weight">
          <InputNumber :min="-1" v-model="formPolicy.weight" />
        </FormItem>
        <divider plain size="small">上级subject</divider>

        <FormItem label="上级起始 subject.start">
          <!-- <Input v-model="formPolicy.subject.start"></Input> -->
          <OdInput v-model="formPolicy.subject.start" />
        </FormItem>
        <FormItem label="上级结束 subject.end" v-if="formPolicy.subject.method == 0">
          <OdInput v-model="formPolicy.subject.end" />

          <!-- <Input v-model="formPolicy.subject.end"></Input> -->
        </FormItem>
        <div class="d-flex" style="gap: 20px;">
          <FormItem label="上级方法 subject.method" class="pr-5">
            <!-- <Input v-model="formPolicy.subject.method"></Input> -->
            <Select v-model="formPolicy.subject.method">
              <Option :value="1">纵向</Option>
              <Option :value="0">横向</Option>
            </Select>
          </FormItem>
          <FormItem label="上级深度 subject.downlevel">
            <InputNumber :min="0" v-model="formPolicy.subject.downlevel" />
            <!-- <Input v-model="formPolicy.subject.downlevel"></Input> -->
          </FormItem>
        </div>
        <divider plain size="small">下级object</divider>
        <FormItem label="下级起始 object.start">
          <OdInput v-model="formPolicy.object.start" />
        </FormItem>
        <FormItem label="下级结束 object.end" v-if="formPolicy.object.method == 0">
          <OdInput v-model="formPolicy.object.end" />
        </FormItem>
        <div class="d-flex" style="gap: 20px;">
          <FormItem label="下级方法 object.method">
            <!-- <Input v-model="formPolicy.object.method"></Input> -->
            <Select v-model="formPolicy.object.method">
              <Option :value="1">纵向</Option>
              <Option :value="0">横向</Option>
            </Select>
          </FormItem>
          <FormItem label="下级深度 object.downlevel">
            <InputNumber :min="0" v-model="formPolicy.object.downlevel" />
          </FormItem>
        </div>
      </Form>
      <div slot="footer" style="text-align: end">
        <Button class="btn_save" @click="saveChange" type="primary">
          保存
        </Button>
      </div>
    </Modal>
    <Modal v-model="modalView" title="查看权限" width="720">
      <PolicyForm :formPolicy="curPolicy"></PolicyForm>
      <template #footer>
        <div style="text-align: end">
          <Button class="btn_save" @click="delectPolicy" type="error">
            删除
          </Button>
        </div>
      </template>

    </Modal>
  </div>
</template>

<script>
import { policyCreate, policyDelete, policyView } from "@/api/frame";
import OdInput from "@/components/common/odInput/OdInput";
import { useNodeStore } from '@/stores/node';
import PolicyForm from "./PolicyForm";

export default {
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore,
    }
  },
  // props: ["card"],
  inject: ["_curNode"],
  components: {
    OdInput,
    PolicyForm
  },
  data() {
    return {
      columns: [
        {
          title: '序号',
          key: 'num',
          slot: 'num',
          width: 60
        },
        {
          title: '上级',
          key: 'subject',
          slot: 'subject',
          width: 220
        },
        {
          title: '下级',
          key: 'object',
          slot: 'object', width: 220
        },
        {
          title: '权限',
          key: 'action'
        },
        {
          title: '权限属性',
          key: 'actionParams'
        },
        {
          title: '正负',
          key: 'positive',
          width: 60
        },
        {
          title: '权重',
          key: 'weight',
          width: 60
        }
      ],
      formPolicy: {
        action: "",
        object: {
          method: 1,
          downlevel: -1,
          start: this._curNode.position.coordinate
        },
        subject: {
          method: 1,
          downlevel: -1,
          start: []
        },
        weight: 10,
        positive: 1
      },
      curPolicy: {
        object: {},
        subject: {}
      },
      abilityList: [],
      modalCreate: false,
      modalView: false
    };
  },
  methods: {
    saveChange() {
      const policy = { ...this.formPolicy, frameId: this.nodeStore.frame._id };
      policyCreate(policy).then((res) => {
        this.$Message.success('创建成功');
        this.abilityList.push(res.data.data);
      });
    },
    initUser() {
      policyView(this._curNode.node._id, this.nodeStore.frame._id).then(
        (res) => {
          this.abilityList = res.data.data;
        }
      );
    },
    selectPolicy(policy) {
      this.curPolicy = policy
      this.modalView = true
    },
    delectPolicy() {
      this.$Modal.confirm({
        title: '确认删除',
        content: '<p>删除该权限</p>',
        onOk: () => {
          policyDelete({ id: this.curPolicy._id }).then((res) => {

            this.abilityList.splice(this.abilityList.findIndex(item => item._id === this.curPolicy._id), 1)
            this.$Message.success('删除成功');

            //  this.abilityList.push(res.data.data);
          });
        },
        onCancel: () => {

        }
      });
      // console.log('curPolicy',this.curPolicy)

    },
  },

  mounted() {
    this.initUser();

  },
};
</script>

<style scoped>
.setting-button {
  width: 99px;
  margin: 8px 0;
}

.card-view {
  min-height: 550px;
  font-size: 14px;
}

.card-view h1 {
  font-size: 19px;
  line-height: 35px;
}

.card-view .ivu-tabs-nav .ivu-tabs-tab {
  padding: 8px 16px 16px;
}

.time {
  font-size: 14px;
  font-weight: bold;
}

.card-monaco-editor {
  height: 100%;
  width: 100%;
}

.policy-label {
  min-width: 60px;
  color: #696969
}
</style>
