<template>
  <Form ref="iForm" class="rendered-form" :label-width="labelWidth" :label-position="labelPosition" :model="formModel"
    @setFormModel="setFormModel" :show-message="false">
    <slot></slot>
    <FormItem v-if="!btnHide" class="rendered-item">
      <i-button :type="resetBtn.type" @click="handleReset" :loading="loadingSearch" style="margin-right: 10px;">
        {{ resetBtn.text }}
      </i-button>
      <i-button :type="submitBtn.type" @click="handleSubmit" :loading="loadingSubmit">
        {{ submitBtn.text }}
      </i-button>
    </FormItem>
  </Form>
</template>

<script>
import { useNodeStore } from '@/stores/node';
import axios from 'axios';

export default {
  components: {},
  props: {
    labelPosition: {
      type: String,
      default: 'left',
    },
    labelWidth: {
      type: [Number, String],
      default: 100,
    },
    btnHide: {
      type: Boolean,
      default: true,
    },
    submitBtn: {
      type: Object,
      default: () => {
        return {
          text: '提交',
          type: 'primary',
          style: {},
        }
      },
    },
    resetBtn: {
      type: Object,
      default: () => {
        return {
          text: '重置',
          type: 'default',
          style: {},
        }
      },
    },
    type: {
      type: String,
    },
    action: {
      type: String,
    },
    actionType: {
      type: String,
    },
    variety: {
      type: String,
    },
    tags: Array,
    edges: Array,
    //  extend: Boolean,
    coordinate: Array,
    requestParams: Object
  },
  inject: {
    formModel: 'formModel',
    targetId: 'targetId',
    formSetting: {
      default: () => {
        return undefined
      },
    },
    getCurNode: 'getCurNode',
    _curNode: {
      from: "_curNode",
      default: () => {
        return {}
      },
    },
    preIds: {
      default: () => {
        return null
      },
    },
    branch: {
      default: () => {
        return () => { }
      },
    },
    reversely: "reversely",
    extend: 'extend',


  },
  data() {
    //  let formInternal = this.formModel ? cloneDeep(this.formModel) : {}
    let formInternal = this.formModel
    return {
      formInternal,
      loadingSearch: false,
      loadingSubmit: false,
    }
  },
  methods: {
    async subjectId(preIdItem) {
      if (preIdItem.subjectParam) {
        //基于外键，标识 subjectParam
        return this.formModel[preIdItem.subjectParam]._id
      } else if (preIdItem.subjectOd) {
        //基于od，标识 subjectOd
        let preNode = this.nodeStore.getNode({
          coordinate: preIdItem.subjectOd,
          frameId: this.nodeStore.frame._id,
        })

        if (!preNode) {
          //待定
          preNode = await this.nodeStore.getNodeByOd({
            coordinate: preIdItem.subjectOd,
            frameId: this.nodeStore.frame._id,
          })

        }
        return preNode.node._id
      } else {
        //基于环境上级
        return this._curNode.node._id
      }
    },
    handleReset() {
      console.log('reset')

      //this.$refs.iForm.resetFields()
      // Object.assign(this.formModel, {});
      const model = this.formModel
      console.log('model', model)

      for (let key in model) {
        delete model[key]
      }
      this.$emit('reset', this.formModel)
    },
    async handleSubmit() {
      console.log("handleSubmit", this.curContainer)
      console.log("this._curNode", this._curNode,)
      console.log("this.formModel", this.formModel)
      console.log("this.actionType1", this.actionType, this.formModel)

      this.loadingSubmit = true
      // this.$emit('submit', this.formModel)
      //创建
      if (this.actionType === 'create') {
        this.createSubmit()
      } else if (this.actionType === 'update') {
        //修改
        this.updateSubmit()

      } else if (this.actionType === 'request') {
        console.log('request', this.requestParams)
        this.requestSubmit()

      } else {
        let formValue = this.formModel

        for (let key in formValue) {
          if (formValue[key] == null) {
            delete formValue[key]
          }
        }

        this.$emit('submit', this.formModel)
        console.log('submit1', this.formModel)
        this.loadingSubmit = false
      }
    },

    async createSubmit() {
      console.log("this.getCurNode1", this.getCurNode())

      let curNode = this.getCurNode()

      if (this.edges) { }
      const payload = this.formInternal//this.formModel
      console.log("this.formModel1", payload)

      axios
        .post(this.action, {
          payload: this.formInternal,//this.formModel,
          variety: this.variety,
          frameId: this.nodeStore.frame._id,
          targetId: this.targetId,
          //preNode: preNode,
          scene: this.scene,
          //branch: this.branch(),
        })
        .then((res) => {
          const ways = res.data.ways
          let branchs = this.branch()
          if (typeof (branchs) == 'string') {
            branchs = [branchs]
          } else if (!branchs) {
            branchs = []
          }
          console.log("branchs", branchs, ways)
          if (ways) {
            //this.nodeStore.initNode(ways)
            let defaultWay = ways.find(way => way.index == 'default')
            console.log('defaultWay', defaultWay, branchs)
            let newNodes = this.nodeStore.initWay2(defaultWay, branchs, true, this.reversely)

            if (this.extend) {
              this.nodeStore.insertExtend(curNode, newNodes)
            }
            this.$emit('submit', ways)
          } else {
            const card = res.data
            if (card) {
              this.nodeStore.initWay2(card, branchs, true, this.reversely)

              // console.log('insertBranch2', { id: this.branch(), nodes: card })

              // branchs.map(branch => {
              //   this.nodeStore.insertBranch({ id: branch, nodes: card })
              // })
            }
            this.$emit('submit', card)
          }

          if (res.status == 200) {
            this.$Message.success(`${res.data.msg}`)
          }
          this.loadingSubmit = false
        })
        .catch((error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
          this.loadingSubmit = false
        })
    },
    async updateSubmit() {
      const card = this._curNode
      console.log("this.formModel", this.formModel, card)

      const tags = this.formModel?.tag
      const edges = this.formModel?.edge
      console.log("card11", card)

      const res = await this.nodeStore.updateCard({
        action: this.action,
        vid: card.vid,
        tags: tags,
        edges: edges,
        scene: this.scene,
      })
      this.loadingSubmit = false
      console.log("res", res)
      this.$Message.success(res?.msg)
    },
    async requestSubmit() {
      axios
        .post(this.action, {
          payload: this.formModel,
          variety: this.variety,
          frameId: this.nodeStore.frame._id,
          requestParams: this.requestParams,
          scene: this.scene,
        })
        .then((res) => {
          const nodes = res.data.nodes
          let branchs = this.branch()
          if (typeof (branchs) == 'string') {
            branchs = [branchs]
          }

          if (nodes) {
            for (let i = 0, l = nodes.length; i < l; i++) {
              this.nodeStore.initNode(nodes[i].records)
              if (nodes[i].branch == this.branch()) {
                branchs.map(branch => {
                  //  this.nodeStore.initBranch({ id: branch, nodes: card })
                })
                // this.nodeStore.insertBranch({ id: this.branch(), list: nodes[i].records })
              }
            }
          } else {
            const card = res.data.container
            if (card) {
              this.nodeStore.initNode([card])
              branchs.map(branch => {
                //  this.nodeStore.initBranch({ id: branch, nodes: card })
              })
            }
            this.$emit('submit', card)
          }

          if (res.status == 200) {
            this.$Message.success(`${res.data.msg}`)
          }
          this.loadingSubmit = false
        })
        .catch((error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
          this.loadingSubmit = false
        })
    },
    setFormModel(model) {
      this.$parent.$emit('setFormModel', model)
    }
  },
  provide: function () {
    return {
      handleSubmit: this.handleSubmit,
      handleReset: this.handleReset,
      labelWidth: this.labelWidth,
      labelPosition: this.labelPosition,
      keyPath: "",
    };
  },

  beforeCreate() {
    // provide('fromDef', () => {
    //   return {
    //     action: this.action,
    //     actionType: this.actionType,
    //     variety: this.variety,
    //   }
    // })

    // provide('handleSubmit', this.handleSubmit)
  },
  created() {
    // if (this.edges) {
    //   if (!this.formModel?.edge) {
    //     this.formModel.edge = {}
    //   }

    //   this.edges.map(n => {
    //     this.formModel.edge[n] = {}
    //   })
    // }
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
}
</script>

<style lang="less">
.rendered-form {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.form-item-controls {
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
}

.form-item-add {
  margin-bottom: 1em;
}

.rendered-item {
  padding: 0 12px;
  flex: 0 0 auto;
  width: 100%;
  text-align: right;
  margin-bottom: 18px;

  .ivu-input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #dcdee2;
    background-color: #ffffff80;
  }

  .ivu-select-selection {
    background-color: #ffffff80;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #dcdee2;
  }
}

.ivu-form-label-top .rendered-item {
  margin-bottom: 8px;
}

.rendered-item:last-child {
  margin-bottom: 0;
  // padding-right: 12px;
}
</style>
