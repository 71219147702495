<template>
  <div class="d-flex grid-value" style="">
    <div class="cg-pic mr-10" v-for="(img, i) in _curNode?.node?.payload[inputParams.propertyId]" :key="i">
      <!-- <img :src="img" /> -->
      <Image :src="img" fit="contain" preview :preview-list="_curNode?.node?.payload[inputParams.propertyId]"
        :initial-index="0">
        <template #error>
          <Icon type="ios-image-outline" size="24" color="#ccc" />
        </template>
      </Image>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
  },
  inject: ["_curNode"],
};
</script>
<style lang="less" scoped>
@import '../cardGrid.less';
</style>
