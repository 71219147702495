<template>
  <div>
    <!-- <div class="from-lable">{{inputParams.label}}</div> -->
    <div class="from-content">
      {{ formModel[inputParams.propertyId] }}
    </div>
    <!-- <div>{{ selfResult.toFixed(2) }}</div> -->
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["formModel"],

  components: {
  },
  computed: {
    selfResult() {
      console.log(this.formModel, this.inputParams);
      const configJson = JSON.parse(this.inputParams.config);

      let res = 0;
      switch (configJson.calc.action) {
        case "add":
          res = this.add(configJson.calc.params);
          break;
        case "sub":
          res = this.sub(configJson.calc.params);
          break;
        case "mul":
          res = this.mul(configJson.calc.params);
          break;
        case "div":
          res = this.div(configJson.calc.params);
          break;
      }
      this.formModel[this.inputParams.index] = res;
      return res;
    },
  },
  methods: {
    execute(calc) {
      let res;
      console.log("calc.action", calc.action);
      switch (calc.action) {
        case "add":
          res = this.add(calc.params);
          break;
        case "sub":
          res = this.sub(calc.params);
          break;
        case "mul":
          res = this.mul(calc.params);
          break;
        case "div":
          res = this.div(calc.params);
          break;
      }
      return res;
    },
    add(arr) {
      let res = this.formModel[arr[0].id];
      console.log("res", res, arr, this.formModel);
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].type === "self") {
          res += this.formModel[arr[i].id];
        } else if (arr[i].type === "calc") {
          res += this.execute(arr[i].calc);
        }
      }
      return res;
    },
    sub(arr) {
      let res = this.formModel[arr[0].id];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].type === "self") {
          res -= this.formModel[arr[i].id];
        } else if (arr[i].type === "calc") {
          res -= this.execute(arr[i].calc);
        }
      }
      return res;
    },
    mul(arr) {
      let res = this.formModel[arr[0].id];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].type === "self") {
          res *= this.formModel[arr[i].id];
        } else if (arr[i].type === "calc") {
          res *= this.execute(arr[i].calc);
        }
      }
      return res;
    },
    div(arr) {
      let res = this.formModel[arr[0].id];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].type === "self") {
          res /= this.formModel[arr[i].id];
        } else if (arr[i].type === "calc") {
          res /= this.execute(arr[i].calc);
        }
      }
      return res;
    },
  },
};
export const isStringType = true;
</script>
