<template>
  <div class="grid-value">
    {{ numberValue }}
    <span class="grid-value-unit">{{ unit }}</span>
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
  },
  inject: ["curNode", "preNode"],
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this.curNode()?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        let edge = this.curNode().edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })
        return edge?.props
      }
    },
    numberValue() {
      let value = this.formModelEntity?.[this.inputParams.prop]
      if (typeof value == 'number') {
        return value?.toFixed(
          this.inputParams.float ? this.inputParams.float : 0
        )
      }
    },
    unit() {
      return this.inputParams.unit
    }
  },
};
</script>
<style lang="less" scoped>
@import "../cardGrid.less";

.grid-value-unit {
  color: #b7b7b7;
  font-size: 12px;
}
</style>
