<template>
  <div style="display:flex;gap:16px">
    <input id="file1" type="file" name="pic" @change="showPic($event)">

  </div>
</template>

<script>
import FileCardCreate from "@/components/plot/FileCardCreate";
export default {
  props: {
    inputParams: Object
  },
  inject: ["formModel"],
  components: {
    FileCardCreate,
  },
  methods: {
    showPic(obj) {
      let file = document.getElementById("file1").files


      console.log("file", file)
      let reader = new FileReader();//这是核心,读取操作就是由它完成.
      //reader.readAsText(selectedFile);//读取文件的内容,也可以读取文件的URL
      reader.onload = function () {
        //当读取完成后回调这个函数,然后此时文件的内容存储到了result中,直接操作即可
        console.log("this.result", this.result);
      }

      // function getObjectURL (file) {
      //   let url = null;
      //   // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
      //   if (window.createObjectURL != undefined) {   // basic
      //     url = window.createObjectURL(file);
      //   } else if (window.URL != undefined) {        // mozilla(firefox)
      //     url = window.URL.createObjectURL(file);
      //   } else if (window.webkitURL != undefined) {  // webkit or chrome
      //     url = window.webkitURL.createObjectURL(file);
      //   }
      //   return url;
      // }

      // let fileUrl = getObjectURL(obj.files[0]);
      // if (fileUrl != null) {
      //   document.getElementById("preview").src = fileUrl;      // 图片预览
      //   document.getElementById("preview").style = "opacity:1";
      // }
    },
  },
  computed: {
    modelValue: {
      get: function () {
        return this.formModel()[this.inputParams.propertyId];
      },
      set: function (newValue) {
        this.formModel()[this.inputParams.propertyId] = newValue;
      },
    },
    fileType: {
      get: function () {
        const url = this.modelValue
        if (typeof (url) == 'string') {
          if (/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(url)) {
            return "img"
          } else if (/\.(pdf)$/i.test(url)) {
            return "pdf"
          } else if (/\.(mp4|avi|webm)$/i.test(url)) {
            return "video"
          } else if (/\.(xls|xlsx|xlsm|xlsb|csv)$/i.test(url)) {
            return "excel"
          }
        }
      },
    }
  },
  mounted() {
    if (this.inputParams.default) {
      this.formModel()[this.inputParams.propertyId] = this.inputParams.default;
    }
  },
};
export const isStringType = true;
</script>
<style scoped lang="less">
.item-pic {
  width: 76px;
  height: 76px;
  flex: 0 0 76px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.blank-file {
  width: 76px;
  height: 76px;
  // background: #ddd;
  background: #ffffff;
  text-align: center;

  .file-icon {
    line-height: 76px;
    font-size: 40px;
  }
}

.bg-pdf {
  background: #ffffff;
  color: #223c5e;
}

.item-input {
  flex: 1 1 auto;
}
</style>
