<template>
  <div>
    <!-- <i-input v-model="formModel()[inputParams.index]"
             :show-word-limit="inputParams.maxLength > 0"
             :maxlength="inputParams.maxLength > 0 ? inputParams.maxLength : null"
             :placeholder="inputParams.placeholder"
             :disabled="disabled" /> -->
    <RadioGroup v-model="modelValue" :disabled="disabled">
      <Radio :label="item.value" v-for="(item, key) in inputParams.options" :key="key">
        <span>{{ item.label }}</span>
      </Radio>
    </RadioGroup>
  </div>
</template>

<script>
// import Radio from "component/extraIview/Radio"
import { getFun, setFun } from '../modelValue.js';

export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["formModel", "keyPath"],
  computed: {
    modelValue: {
      get: function () {
        const value = getFun(this.formModel, this.inputParams)
        if (value === null || value === undefined) {
          setFun(this.formModel, this.inputParams, this.inputParams.default)
        }
        return getFun(this.formModel, this.inputParams)
      },
      set: function (newValue) {
        return setFun(this.formModel, this.inputParams, newValue, this.keyPath)
      },
    },
  },
  components: {
    // Radio,
  },
};
export const isStringType = true;
</script>
