<template>
  <div class="card-view">
    <Row :gutter="12">
      <i-col span="16" class="mt-2" style="
          overflow: auto;
          padding-left: 12px;
          padding-right: 12px;
        ">

        <div class="border p-2 mb-5">
          <p class="mb-1">
            <span class="font-500 mr-5 text-primary">vid：</span>
            {{ _curNode.vid }}
          </p>
          <p class="mb-1">
            <span class="font-500 mr-5 text-primary">tag：</span>
            {{ Object.keys(_curNode?.tag) }}
          </p>
        </div>
        <!-- <div class="border p-2 mb-5 " style="position: relative;">
          <p class="mb-3">
            <span class="font-500 mr-5 text-primary">edge </span>
            {{ _curNode?.edge }}
          </p>
        </div> -->
        <div style="height: 600px;">
          <MonacoEditor v-if="showJson" class="card-monaco-editor border" :value="modelValue" language="json" :options="{
            automaticLayout: true,
            foldingStrategy: 'indentation',
          }" ref="json_monacoEditor" />
        </div>
      </i-col>

      <i-col span="8">
        <div class="cr-cell mt-2">
          <Button @click="confirmDelete = true" style="color: #ed4014">
            删除卡片
          </Button>
        </div>
        <div class="cr-cell mt-2">
          <Button @click="showJson = !showJson" style="color: rgb(20, 132, 237)">
            节点代码
          </Button>
        </div>
      </i-col>
    </Row>
    <Modal v-model="confirmDelete" title="确定删除" @on-ok="deleteCard">
      <p>卡片内所以部门将解散、人员将被移除</p>
    </Modal>

  </div>
</template>

<script>
import { deleteEdge } from "@/api/graph";
import MonacoEditor from "@/components/common/editor/meditor.js";
import { useNodeStore } from '@/stores/node';

export default {
  // props: ["card"],
  inject: ["_curNode", "preNode"],
  components: {
    MonacoEditor: MonacoEditor
  },
  data() {
    return {
      loading: false,
      showJson: false,
      confirmDelete: false,
      confirmDeleteEdge: false,

      count: [],
      open: false,
      cardEndDate: "",
      boardLogs: [],
      formTop: {
        input1: "",
        input2: "",
        input3: 1,
      },
      cardStatus: true,
    };
  },
  methods: {
    async deleteCard() {
      //const edges = this.preNode?.reEdge.filter(e => e.dst === this._curNode.vid)
      const edges = this._curNode?.reEdge.filter(e => e.dst === this.preNode.vid)
      // const reEdges = this.preNode?.edge.filter(e => e.src === this._curNode.vid)
      const reEdges = this._curNode?.edge.filter(e => e.src === this.preNode.vid)

      console.log('deleteCard', this.preNode, this._curNode)
      console.log('deleteCard', edges, reEdges)

      let response = await deleteEdge({ frameId: this.nodeStore.frame._id, edges: [...edges, ...reEdges] })
      let res = this.nodeStore.initWays(response?.data?.ways);


      if (res) {
        this.$Message.success("已删除");

      }
      this.confirmDelete = false;
    },
    reflash() { },
  },
  computed: {
    modelValue: {
      get: function () {

        let value = this._curNode;
        let json = JSON.stringify(value, null, 2);

        return json ? json : "";
      },
      // set: function(newValue) {
      //   this.formModel()[this.inputParams.index] = JSON.parse(newValue);
      // },
    },
  },
  mounted() { },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore,
    }
  }
};
</script>

<style scoped>
.setting-button {
  width: 99px;
  margin: 8px 0;
}

.card-view {
  min-height: 550px;
  font-size: 14px;
}

.card-view h1 {
  font-size: 19px;
  line-height: 35px;
}

.card-view .ivu-tabs-nav .ivu-tabs-tab {
  padding: 8px 16px 16px;
}

.time {
  font-size: 14px;
  font-weight: bold;
}

.card-monaco-editor {
  height: 100%;
  width: 100%;
}
</style>
