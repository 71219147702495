<template>
  <Button v-bind="btnParams.props" class="re-small" :style="btnParams.style" v-on:click.stop="createCardModalShow = true">
    {{ title ? title : "弹窗" }}
  </Button>
  <Modal v-model="createCardModalShow" :title="title" v-bind="modalProps" footer-hide>
    <div v-if="createCardModalShow" style="margin: -16px;">
      <slot></slot>
    </div>
  </Modal>
</template>
<script>
import { useNodeStore } from '@/stores/node';

export default {
  props: {
    form: Object,
    title: String,
    branch: String,
    coordinate: { type: [Array, String, undefined] },
    initCardParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
    btnParams: {
      type: Object,
      default: function () {
        return {
          props: {
            long: false,
            type: "default",
            size: "auto",
          },
        };
      },
    },
    modalProps: {
      type: Object,
      default: function () {
        return {
          styles: {
            top: "60px",
            width: "1280px",
          },
          title: "弹窗",
        };
      },
    },
    requestParams: Object,
  },

  inject: {
    _curNode: {
      from: '_curNode',
      default: {}
    },
    curNode: {
      from: 'curNode',
      default: () => { }
    },
  },
  components: {},
  data () {
    return {
      createCardModalShow: false,
      createCardModal: false, // 创建卡片弹窗
      createformModel: {},
    };
  },
  computed: {},
  mounted () { },
  methods: {},
  setup () {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less"></style>
