<template>
  <div v-for="(item, i) in modelValue" :key="i" class="d-flex edge-item">
    <EdgeItem :index="i" v-if="item" :key="i" style="width: 100%;">
      <slot></slot>
    </EdgeItem>
  </div>
</template>

<script>
import EdgeItem from './EdgeItem';
export default {
  props: {
    inputParams: Object,
  },
  inject: ["_curNode"],
  provide: function () {
    return {
      _curNode: this.modelValue,
    };
  },
  components: { EdgeItem },
  data() {
    return {};
  },
  methods: {},
  computed: {
    modelValue: {
      get: function () {

        return this._curNode?.edge
      },
      set: function (newValue) {
        this._curNode.edge = newValue;
      },
    },
  },
  watch: {
    modelValue(newValue) {
      console.log("newValue", newValue);
    },
  },
  mounted() {
    //this.tempArr = this._curNode?.edge

    // if (!this.inputParams?.edgeCount) {
    //   this.modelValue.fill({})
    // }
  },
};
</script>
<style lang="less" scoped>
.delete-btn {
  visibility: hidden;
}

.edge-item {
  width: 100%;
}

.edge-item:hover {
  .delete-btn {
    visibility: visible
  }
}
</style>