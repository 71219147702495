<template>
  <div style="text-align: end;">
    <i-button :type="inputParams.buttonType" @click="handleSubmit" :loading="loadingSubmit">
      {{ inputParams.text }}
    </i-button>
  </div>
</template>

<script>

export default {
  props: {
    inputParams: {
      type: Object,
      default () {
        return { buttonType: 'primary' }
      }
    },
  },

  inject: {
    handleSubmit: 'handleSubmit',
    formModel: 'formModel',

  },
  data () {
    return {
      loadingSubmit: false
    }
  },
  mounted () { },
  methods: {
    reset () {
      this.$refs.iForm.resetFields()
    },
    setFormModel (model) {
      this.$parent.$emit('setFormModel', model)
    },
  },
};
export const isStringType = true;
</script>
