<template>
  <video :ref="randomRef" class="video-js vjs-default-skin" width="600" height="400" controls v-if="src">
    <source :src="src" />
  </video>

  <!-- <video class="video-js" ref="videoid" width="600" height="400" controls></video> -->
</template>
<script>
import { useNodeStore } from '@/stores/node';
import "video.js/dist/video-js.css";
import videojs from "video.js";

export default {
  props: {
    option: Object,
    src: String,
    modalParams: {
      type: Object,
      default: function () {
        return {
          props: {
            styles: {
              top: "60px",
              width: "1280px",
            },
            title: "新建",
          },
        };
      },
    }
  },
  inject: {
    curNode: 'curNode',
  },
  components: {},
  data () {
    return {
      playerId: "videoid",
      randomRef: ''
    };
  },
  beforeCreate () {
    const randomString = Math.random().toString(36).substring(7);
    this.randomRef = randomString;
  },
  created () {

    setTimeout(() => {
      this.$nextTick(() => {
        this.player = videojs(this.$refs.video);
        // const player = videojs(this.playerId, { autoplay: false });
        // player.src(this.src);
        // player.on("ended", () => {
        //   //播放完成
        // })
      });
    }, 1300)
  },
  methods: {},
  setup (params) {

    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less"></style>
