<template>
  <div>
    <Button v-bind="btnParams.props" class="re-small" :style="btnParams.style"
      v-on:click.stop="createCardModalShow = true">
      <slot></slot>
      {{ title }}
    </Button>
    <Modal v-model="createCardModalShow" v-bind="modalParams.props" footer-hide>
      <div style="padding: 12px" v-if="createCardModalShow">
        <FromPlotRender v-if="form.scene && form?.action" :scene="form.scene"
          :params="{ preId, formModel, requestParams, branch }" :action="form?.action" @submit="onSubmit" />
        <FromPlotRender v-else-if="form.scene" :scene="form.scene" :params="{ preId, formModel, requestParams, branch }"
          @submit="onSubmit" />

      </div>
    </Modal>
  </div>
</template>
<script>
import FromPlotRender from "@/components/plotRender/FromPlotRender";
import { useNodeStore } from '@/stores/node';
/**
* 用于创建节点，由按钮，和弹窗组成，弹窗内包含表单。
* @param {string} form - 决定表单属性.
* @param {string} form.scene - 引入的表单的标识.
* @param {string} [form.formModel] - 表单的默认值.
* @param {string} [coordinate] - 创建类型的表单提交的父节点
* @param {string} [title] - 弹窗的标题
* @param {string} [btnParams] - 按钮属性
* @param {string} [modalParams] - 弹窗属性
* @param {string} [requestParams] - 表单请求内容
*/
export default {
  props: {
    form: Object,
    title: String,
    branch: [String, Array],
    reversely: [String, Boolean],
    // coordinate: { type: [Array, String, undefined] },
    preId: { type: String },

    btnParams: {
      type: Object,
      default: function () {
        return {
          props: {
            long: false,
            type: "default",
            size: "auto",
          },
        };
      },
    },
    modalParams: {
      type: Object,
      default: function () {
        return {
          props: {
            styles: {
              top: "60px",
              width: "1280px",
            },
            title: "新建",
          },
        };
      },
    },
    requestParams: Object,
    extend: Boolean,
  },
  // inject: {
  //   //curContainer: "curContainer",
  //   _curNode: "_curNode"
  // },
  inject: {
    _curNode: {
      from: '_curNode',
      default: {}
    }
  },
  components: {
    FromPlotRender
    // PlotFormRender: () => import("component/common/PlotControl/PlotFormRender"),
  },
  data() {
    return {
      buttonProps: {
        type: "default",
        size: "small",
      },
      defaultParams: {},
      cardTypeIndex: 0,
      createCardModalShow: false,
      typeSelectModal: false, // 选择卡片类型弹窗
      createCardModal: false, // 创建卡片弹窗
      cardTypes: [],
      createformModel: {},
    };
  },
  computed: {
    formDef: function () {
      // this.nodeStore.
      // const datapoolForm = this.$store.getters["datapool/getForm"](
      //   this.form.scene
      // );
      // let formInternal = cloneDeep(datapoolForm);
      // if (formInternal) {
      //   return formInternal;
      // } else {
      //   return false;
      // }
    },
    formModel: {
      get: function () {
        if (this.form.formModel) {
          Object.assign(this.createformModel, this.form.formModel);
        }
        return this.createformModel;
      },
      set: function (value) {
        this.createformModel = value;
      },
    },
  },
  mounted() {
    if (this.form.formModel) {
      Object.assign(this.createformModel, this.form.formModel);
    }
  },
  methods: {
    onSubmit(card) {
      this.$emit("submit", card);
    }
  },
  provide: function () {
    return {
      targetId: this.form?.targetId,
      formSetting: () => this.form,
      curNode: this.form.curNode,
      getCurNode: () => {
        // console.log("coordinate_curNode", this.coordinate, this.nodeStore.getNode({
        //   coordinate: this.coordinate,
        //   frameId: this.nodeStore.frame._id,
        // }))
        console.log("this.preId", this.preId)
        return this.nodeStore.getNodeById(this.preId);

        // if (this.coordinate) {
        //   return this.nodeStore.getNode(this.preId);
        // } else {
        //   return this._curNode //this.curContainer();
        // }
      },
      branch: () => this.branch,
      reversely: this.reversely,
      extend: this.extend,
    };
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less">
.item-label {
  padding: 8px;
}
</style>
