<template>
  <div v-if="inputParams" :style="{
    height: inputParams.height ? inputParams.height : height,
    position: 'relative',
  }">
    <Button @click="targleEditor" v-if="!showEditor">编辑</Button>
    <MonacoEditor v-if="showEditor" class="table-monaco-editor" :value="contentStr" @change="contentStr = $event"
      language="json" :options="{
        automaticLayout: true,
        foldingStrategy: 'indentation',
      }" ref="json_monacoEditor" />
  </div>
</template>

<script>
import MonacoEditor from "@/components/common/editor/meditor.js";
import { getFun, setFun } from '../modelValue.js';


export default {
  props: {
    inputParams: Object,
  },
  data() {
    return {
      showEditor: false,
      height: 'auto'
    };
  },
  inject: ["formModel", "keyPath"],
  methods: {
    targleEditor() {
      this.height = "300px"
      this.showEditor = true
    }
  },
  components: {
    MonacoEditor
  },
  computed: {
    modelValue: {
      get: function () {
        return getFun(this.formModel, this.inputParams, this.keyPath)
      },
      set: function (newValue) {
        return setFun(this.formModel, this.inputParams, newValue, this.keyPath)
      },
    },
    contentStr: {
      get: function () {
        // if (this.inputParams.default) {
        //   this.formModel()[
        //     this.inputParams.propertyId
        //   ] = this.inputParams.default;
        // }
        // let value = this.formModel()[this.inputParams.propertyId];
        let value = getFun(this.formModel, this.inputParams, this.keyPath)
        // let json = JSON.stringify(value, null, 2);
        //  return json ? json : "";
        return value
      },
      set: function (newValue) {
        console.log("newValue12", newValue)

        if (typeof (newValue) == 'string') {
          // this.formModel()[this.inputParams.index] = JSON.parse(newValue);
          return setFun(this.formModel, this.inputParams, newValue, this.keyPath)

        }

      },
    },
  },
  watch: {
    // modelValue(newValue) {
    //   console.log("newValue", newValue);
    // },
  },

  mounted() { },
};
export const isStringType = true;
</script>
<style lang="less" scoped>
.table-monaco-editor {
  height: 100%;
  width: 100%;
  min-height: 600px;
  // min-width: 100px;
}
</style>
