<template>
  <div>
    <Button v-if="cards?.length > 0" type="text" shape="circle"
      :icon="showSub ? 'md-arrow-dropdown' : 'md-arrow-dropleft'" @click="showSub = !showSub" style="position: absolute;
    right: 0;
    top: -31px;"></Button>
    <template v-if="showSub">
      <template v-for="(card, index) in cards">
        <div :key="index" v-if="inItemVariety(card)" @click="showView(card, index)" @click.right="showViewRight(index)"
          :class="[
            'card-item-wrap', 'd-flex',
            activeId === card.node._id ? 'active' : '',
          ]">

          <PlotRender v-if="card" :scene="itemSceneSelect(card)" :params="{ _curNode: card, card: cards[index] }"
            :key="cards[index].node._id" style="flex: 1 0 auto;" :card="cards[index]" />
        </div>
        <template v-if="depth > 0 || depth == -1">
          <BlockList v-if="inItemVariety(card)" :depth="depth - 1" :key="card.node._id" :initCardParams="initCardParams"
            :coordinate="card.position.coordinate" :itemScene="itemScene" :cardScene="cardScene"
            :itemScenes="itemScenes" :cardScenes="cardScenes" :clickCardModal="clickCardModal"
            :cardModalStyle="cardModalStyle" :levelOd="levelOd + '_' + index" :curOd="curOd ? curOd : subCurOd"
            :activeId="activeId" :branch="branch" @select="onSelect" style="position: relative;" :noInit="true"
            @update:activeId="activeId = $event" class="sub-level">
          </BlockList>
        </template>
      </template>
      <Modal v-model="showCardModal" :styles="cardModalStyle" footer-hide>
        <div :style="{ minHeight: '480px' }">
          <PlotRender v-if="showCardModal" :scene="cardSceneSelect(cards[curCardIndex])"
            :params="{ _curNode: cards[curCardIndex] }" :key="cards[curCardIndex].node._id">
          </PlotRender>
        </div>
        <div class="card-tool-bar">
          <ButtonGroup>
            <Button @click="lastCard()">
              <Icon type="ios-arrow-back"></Icon>
              上一卡
            </Button>
            <Button @click="nextCard()">
              下一卡
              <Icon type="ios-arrow-forward"></Icon>
            </Button>
          </ButtonGroup>
        </div>
      </Modal>
    </template>
  </div>
</template>
<script>
// import LevelListItem from "./LevelListItem.vue";
// import CardPlotRender from "@/components/plotRender/CardPlotRender.js";
// import PlotRender from "@/components/plotRender/PlotRender.js";
import { useNodeStore } from '@/stores/node';
//const nodeStore = useNodeStore()
import axios from "axios";
import _ from "lodash-es";
export default {
  name: "BlockList",
  props: {
    initCardParams: { type: Object, default: {} },
    branch: String,
    noInit: {
      type: Boolean,
      default: false
    },
    coordinate: Array,
    depth: Number,
    activeState: String,
    itemScene: String,
    cardScene: String,
    itemScenes: Array,
    cardScenes: Array,
    clickCardModal: {
      type: Boolean,
      default: true,
    },
    levelOd: {
      type: String,
      default: "",
    },
    curOd: {
      type: String,
      // default: "_",
    },
    activeId: String,
    includeSelf: {
      type: Boolean,
      default: false,
    },
    cardModalStyle: {
      type: Object,
      default: function () {
        return {
          top: "100px",
          width: "720px",
          minWidth: "1020px",
          maxWidth: "1600px",
        };
      },
    },
    listHandler: String,
    perPage: { type: Number, default: 20 },
    pageMode: {
      type: String,
      default: "paging",
      validator(value) {
        return ["paging", "full"].includes(value);
      },
    },
  },
  data() {
    return {
      curCardIndex: -1,
      showCardModal: false,
      page: 0,
      curIndex: "",
      subCurOd: "",
      paging: {},
      curOdStr: "",
      showSub: true
      // cards: []
    };
  },
  methods: {
    change(od) {
      if (this.levelOd === "") {
        this.subCurOd = od;
      }
      this.$emit("change", od);
    },
    onSelect(card) {
      this.$emit("Select", card)
      this.$emit("select", card)
    },
    showView(card, index) {
      this.$emit("Select", card)
      this.$emit("select", card)
      this.curCardIndex = index;
      //console.log("showView", this.levelOd + "_" + index);
      if (this.levelOd === "") {
        this.subCurOd = this.levelOd + "_" + index;
      } else {
        this.$emit("change", this.levelOd + "_" + index);
      }

      // if (!this.clickCardModal) return;
      this.showCardModal = true;
    },
    lastCard() {
      if (this.curCardIndex !== 0) {
        this.curCardIndex--;
      }
    },
    nextCard() {
      if (this.curCardIndex !== this.cards?.length - 1) {
        this.curCardIndex++;
        console.log("this.curCardIndex", this.curCardIndex)
      }
    },
    showViewRight(index) {
      document.oncontextmenu = function (e) {
        return false;
      };
      this.curCardIndex = index;
      this.showCardModal = true;
    },
    itemSceneSelect(card) {
      const item = this.itemScenes?.find((n) => {
        return n.variety === card.node.variety;
      });
      return item ? item.scene : this.itemScene;
    },
    cardSceneSelect(card) {
      const cardModal = this.cardScenes?.find((n) => {
        return n.variety === card.node.variety;
      });
      return cardModal ? cardModal.scene : this.cardScene;
    },
    inItemVariety(card) {
      if (!card) return false;
      const item = this.itemScenes?.find((n) => {
        return n.variety === card?.node?.variety;
      });
      if (!item) {
        return false;
      } else {
        return true;
      }
    },
    // async getListNode() {
    //   const nodes = await this.nodeStore.initNodes({
    //     position: {
    //       frameId: this.nodeStore.frame._id,
    //       coordinate: this.coordinate,
    //     },
    //     ...this.initCardParams,
    //     depth: 1,
    //     paging: {
    //       perPage: this.perPage,
    //     },
    //   })
    // },
    async fetchContext(current = 1, step = 0) {
      //console.log("fetchContext1", this.coordinate, this.coordinate.length)
      try {
        if (!this.coordinate || this.coordinate.length == 0) {
          return;
        }

        this.loading = true;
        let paramTxt = JSON.stringify(this.initCardParams);
        //  console.log("paramTxt", paramTxt)

        paramTxt = paramTxt.replace(RegExp(_.escapeRegExp("^"), "g"), ".");
        //  console.log("paramTxt2", paramTxt)

        const initCardParams = JSON.parse(paramTxt);
        // console.log("initCardParams2", initCardParams)

        let res
        // console.log("initCardParams1", initCardParams)


        if (this.listHandler) {
          //执行体 获取列表
          console.log("listHandler2", this.listHandler)

          res = await axios.post(this.listHandler, {
            frameId: this.nodeStore.frame._id,
            position: {
              frameId: this.nodeStore.frame._id,
              coordinate: this.coordinate,
            },
            filter: initCardParams.filter,
            query: initCardParams.query,
            sort: initCardParams.sort,
            depth: initCardParams.depth,
            paging: {
              perPage: step == 0 ? this.perPage : step,
              current: current - 1
            }
          })
          res.data.nodes.map(v => {
            this.nodeStore.initNode(v.records)
            if (v.branch == this.branch) {
              this.nodeStore.initBranch({ id: this.branch, list: v.records })
              this.curPageNodes = this.nodeStore.branch[this.branch];
              //  this.curPageNodes = v.records;

              this.paging = v.paging;
            } else if (v.index == 'table') {
              this.nodeStore.initBranch({ id: this.branch, list: v.records })

              this.curPageNodes = v.records;
              this.paging = v.paging;
            }
          })

        } else {

          //通用接口
          res = await this.nodeStore.initNodes({
            index: "table",
            position: {
              frameId: this.nodeStore.frame._id,
              coordinate: this.coordinate,
            },
            filter: initCardParams.filter,
            query: initCardParams.query,
            sort: initCardParams.sort,
            depth: initCardParams.depth,
            paging: {
              perPage: step == 0 ? this.perPage : step,
              current: current - 1,
            },
            //  branchId: this.branch
          })
          // this.nodeStore.initNode(res.records)

          // console.log('initNodes2', this.branch, res?.records)
          this.nodeStore.initBranch({ id: this.branch, list: res?.records })
          this.nodeStore.initNode(res.records)

          this.curPageNodes = res?.records ? res?.records : [];
          this.paging = res?.paging;
        }

        if (!this.paging || this.paging.total <= this.paging.perPage) {
          this.curPageMode = "full";
        } else {
          this.curPageMode = "paging";
        }
      } catch {
        (error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
        }
      } finally {
        this.loading = false;

      }
    },
  },
  computed: {
    cards() {
      let depth = 1
      if (this.depth == -1) {
        depth = -1
      }
      if (depth < -1) {
        return
      }
      let _cards = this.nodeStore.getBranch({
        branchId: this.branch,
        position: {
          frameId: this.nodeStore.frame._id,
          coordinate: this.coordinate,
        },
        depth: depth//this.depth
      })?.nodes
      return _cards
    },

  },
  watch: {
    curOd() {
      this.subCurOd = this.curOd;
    },
    coordinate: {
      deep: true,
      immediate: true,
      handler: async function (value) {
        // console.log('coordinate1', value)
        if (!this.noInit) {
          await this.fetchContext()

        }

        // const nodes = await this.nodeStore.initNodes({
        //   position: {
        //     frameId: this.nodeStore.frame._id,
        //     coordinate: this.coordinate,
        //   },
        //   ...this.initCardParams,
        //   depth: 1,
        //   paging: {
        //     perPage: this.perPage,
        //   },
        // })
      }
    },

  },
  async created() {
    if (!this.curOd) {
      this.subCurOd = "_";
    }

    //请求节点队列
    // const nodes = await this.nodeStore.initNodes({
    //   position: {
    //     frameId: this.nodeStore.frame._id,
    //     coordinate: this.coordinate,
    //   },
    //   ...this.initCardParams,
    //   depth: 1,
    //   paging: {
    //     perPage: this.perPage,
    //   },
    // })

    //获取节点队列
    //  await this.fetchContext()
    // const selfNode = this.nodeStore.getNodes({
    //   position: {
    //     frameId: this.nodeStore.frame._id,
    //     coordinate: this.coordinate,
    //   }
    // })
    // if (this.includeSelf) {
    //   this.cards = [selfNode];
    // } else {
    //   this.cards = selfNode;
    // }
    //监听节点队列变化
    // this.nodeStore.$subscribe((mutation, state) => {
    //   const selfNode = this.nodeStore.getNodes({
    //     position: {
    //       frameId: this.nodeStore.frame._id,
    //       coordinate: this.coordinate,
    //     }
    //   })
    //   if (this.includeSelf) {
    //     this.cards = [selfNode];
    //   } else {
    //     this.cards = selfNode;
    //   }
    // })
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style lang="less" scoped>
@import url("@/styles/board.less");

.sub-level {
  margin-left: 24px;
  margin-bottom: 1px;
}

.list-fold {
  position: relative;
  right: 1px;
  height: 100%;
  bottom: 0px;
  padding: 3px;
  background: #f5f5f5;
  color: #1b3d6b;
}
</style>
