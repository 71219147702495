import { useDatapoolStore } from "@/stores/datapool";
import { useNodeStore } from "@/stores/node";
import { useUserStore } from "@/stores/user";
import "@/styles/board.less";
import "@/styles/index.less";
import _ from "lodash-es";

import axios from "axios";

import { Message } from "view-ui-plus";

import { h, onMounted, ref } from "vue";
import { evalExpression, mapAssign, plotRender } from "./render.js";
let PlotRender;

PlotRender = {
  name: "PlotRender",
  props: {
    plot: {
      type: Object,
    },
    scene: {
      type: String,
    },
    params: {
      type: Object,
    },
  },
  provide() {
    return {
      _curNode: this?.params?._curNode,
      // curEdges: (edge_type = "") => {
      //   const edges = this?.params?._curNode?.edges?.map((edge) => {
      //     return (
      //       edge.src === this?.params?.preNode.vid &&
      //       (!edge_type || edge_type == edge.name)
      //     );
      //   });
      //   // console.log("curEdges", curEdges);
      //   return edges;
      // },
      // curReEdges: (edge_type = "") => {
      //   const edges = this?.params?._curNode?.edges?.map((edge) => {
      //     return (
      //       edge.dst === this?.params?.preNode.vid &&
      //       (!edge_type || edge_type == edge.name)
      //     );
      //   });
      //   return edges;
      // },
      preNode: this?.params?.preNode,
    };
  },
  setup(props) {
    const datapoolStore = useDatapoolStore();
    const nodeStore = useNodeStore();
    const userStore = useUserStore();

    //获取视图
    let plot = props?.plot;
    const scene = props?.scene;
    if (scene) {
      plot = datapoolStore.getDatapoolByIndex("plots", scene, "scene");
    }
    //设定 传参内部变量
    //let params = ref(props?.params)
    let params = props?.params;

    //设定 视图内部变量
    const plotProperty = plot?.property;
    const plotParams = {};
    if (plotProperty) {
      plotProperty.map((n) => {
        plotParams[n.key] = ref(n.value);
        // plotParams[n.key] = n.value
      });
    }
    // const nodeStore = useNodeStore()
    const contextFunctions = {
      curNode: () => {
        return params?._curNode;
      },
      // preNode: () => {
      //   return params?._curNode;
      // },
      curEdge: () => {
        const preNode = params?.preNode;
        const curNode = params?._curNode;
        // console.log("curEdge0", preNode, curNode);

        const edges = curNode?.edge?.filter((edge) => edge.src === preNode.vid);
        let edgeObj = {};
        edges.map((edge) => {
          edgeObj[edge.name] = edge.props;
        });
        // console.log("curEdge", edgeObj);
        return edgeObj;
      },
      curReEdge: () => {
        const preNode = params?.preNode;
        const curNode = params?._curNode;
        const edges = curNode?.reEdge?.filter(
          (edge) => edge.dst === preNode.vid
        );
        let edgeObj = {};
        edges.map((edge) => {
          edgeObj[edge.name] = edge.props;
        });
        return edgeObj;
      },
      curEdges: (edge_type = "") => {
        const edges = params?._curNode?.edge?.filter((edge) => {
          return (
            edge.dst === params?._curNode.vid &&
            (!edge_type || edge_type == edge.name)
          );
        });
        //  // console.log("curEdges1 edges", edges);
        const edgeNodes = [];
        for (let i = 0; i < edges.length; i++) {
          const node = nodeStore.nodes.find((n) => n.vid === edges[i].src);
          edgeNodes.push({ edge: edges[i], node });
        }

        // // console.log("curEdges1 edgeNodes", edgeNodes);

        return edgeNodes;
      },
      curReEdges: (edge_type = "") => {
        const reEdges = params?._curNode?.reEdge?.filter((edge) => {
          return (
            edge.src === params?._curNode.vid &&
            (!edge_type || edge_type == edge.name)
          );
        });
        let edgeNodes = edges.map((edge) => {
          const node = nodeStore.nodes.find((n) => n.vid == edge.dst);
          return { edge, node };
        });
        return edgeNodes;
      },
    };

    const context = ref({
      $states: datapoolStore.states, //设定 全局状态变量
      $branch: nodeStore.branch, //设定 全局状态变量
      $frame: nodeStore.frame,
      userNode: nodeStore.getUserNode(userStore._id),
      //preNodes: preNodes,
      ...params,
      ...plotParams,
      ...contextFunctions, //设定 内部方法
    });
    if (params?._curNode) {
      //  provide('_curNode',  params._curNode)
    }
    const assignParams = (actionObj, eventProps) => {
      // console.log("assignParams", actionObj, eventProps);

      const key = actionObj.key;
      let value = actionObj.value;
      let assignValue;
      if (actionObj.path) {
        assignValue = _.get(eventProps, actionObj.path);
      } else {
        assignValue = evalExpression(value, context.value);
      }
      // console.log("assignParams2", assignValue);

      const assignkey = evalExpression(key, context.value);

      //// console.log("assignParams", context);
      //// console.log("assignValue", assignValue);
      context.value[assignkey] = assignValue;
      // console.log("assignParams21", assignkey, context.value);
    };
    const stateHandler = (actionObj, eventProps) => {
      const key = actionObj.key;
      let value = actionObj.value;
      if (actionObj.path) {
        value = _.get(eventProps, actionObj.path);
      }
      // // console.log("stateHandler1", actionObj, eventProps, value);
      // // console.log("stateHandler2", value, actionObj.path);
      const stateValue = evalExpression(value, context.value);
      const state = evalExpression(key, context.value);
      //// console.log("stateHandler", stateValue, state);

      datapoolStore.states[state] = stateValue;
      //// console.log("datapoolStore", datapoolStore.states);
    };
    const requestHandler = async (
      {
        handler,
        params = {},
        branch,
        partial,
        allInBranch = false,
        msgShow = false,
      },
      eventProps
    ) => {
      try {
        let postParams = {
          ...params,
          ...eventProps,
          frameId: nodeStore.frame._id,
        };
        // params.frameId = nodeStore.frame._id;
        const response = await axios.post(handler, postParams);
        Object.assign(context.value, response?.data?.context);

        let res = nodeStore.initWays(
          response?.data?.ways,
          branch,
          false,
          allInBranch
        );
        if (msgShow) Message.info(response?.data?.msg);
      } catch (error) {
        if (msgShow) Message.error("请求数据失败");

        console.error("请求数据失败:", error);
      }
    };
    const insertBranch = (actionObj, eventProps) => {
      const id = actionObj.id;
      let nodes;
      if (Array.isArray(eventProps)) {
        nodes = eventProps;
      } else {
        nodes = actionObj.value;
      }
      // console.log("insertBranch", id, nodes);
      // if(nodes){
      //   nodeStore.initWay()
      // }
      // params.frameId = nodeStore.frame._id;

      nodeStore.insertBranch(id, nodes);
      // console.log("nodeStore.insertBranch", id, nodes);
    };
    const removeBranch = (actionObj, eventProps) => {
      const id = actionObj.id;
      let node;
      if (Array.isArray(eventProps)) {
        node = eventProps;
      } else {
        node = actionObj.value;
      }
      // console.log("removeBranch", id, node.vid);

      nodeStore.removeBranch(id, node.vid);
    };
    //事件行为队列执行器
    const actionsDeal = (event, context, eventProps) => {
      event.actions.map((action) => {
        const actionObj = mapAssign(action, context);
        switch (action.action) {
          case "stateHandler":
            stateHandler(actionObj, eventProps);
            break;
          case "assignParams":
            //assignParams(actionObj.key, actionObj.value);
            assignParams(actionObj, eventProps);

            break;
          case "request":
            requestHandler(actionObj, eventProps);
            break;
          case "insertBranch":
            insertBranch(actionObj, eventProps);
            break;
          case "removeBranch":
            removeBranch(actionObj, eventProps);
            break;
        }
      });
    };

    //事件处理
    const eventDeal = (node) => {
      // // console.log("eventDeal", node);

      const nodeEvents = node.events;
      let eventObj = {};
      nodeEvents.map((ev) => {
        let eventName = "on" + ev.event;
        //  // console.log("eventName", eventName);

        let fun = ($event) => {
          context.value["$event"] = _.assign({}, $event);
          // console.log("context1-1", context, $event);
          // console.log("ev", ev);
          actionsDeal(ev, context, $event);
          /* ev.actions.map((action) => {
            const actionObj = mapAssign(action, context);
            switch (action.action) {
              case "stateHandler":
                stateHandler(actionObj.key, actionObj.value);
                break;
              case "assignParams":
                assignParams(actionObj.key, actionObj.value);
                break;
              case "requestHandler":
                requestHandler(actionObj.handler, actionObj.params);
                break;
            }
          }); */
          // console.log("context2", context);
        };
        eventObj[eventName] = fun;
      });
      // // console.log("eventObj",eventObj)
      return eventObj;
    };
    let contextValue = _.clone(context.value);
    onMounted(() => {
      let node = plot?.viewStruct;
      if (node?.events) {
        const mountedEvent = node?.events?.find((n) => {
          return n.event === "mounted";
        });
        // // console.log("mountedEvent", mountedEvent);
        if (mountedEvent) {
          actionsDeal(mountedEvent, context);
        }
      }
    });
    return () =>
      plotRender(h, plot?.viewStruct, { context: context, eventDeal });
  },
};
export default PlotRender;
