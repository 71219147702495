<template>
  <div>
    <i-table :columns="columns" border :data="nodes" size="small" @on-row-click="entreRow">
      <template #variety="{ row }">
        <strong v-if="row.node" style="padding:0px;display:block">{{ filterVariety(row) }}
        </strong>
      </template>
      <template #name="{ row }">
        <p style="padding:0px">{{ filterValue(row) }}</p>
      </template>
      <template #action="{ row }">
        <div style="padding:0px">
          <Button type="default" class="select-btn " style="margin-right: 4px;" size="small" @click="selectRow(row)">
            查看
          </Button>
          <Button type="primary" class="select-btn " size="small" @click="selectRow(row)">
            选择
          </Button>
        </div>
      </template>
    </i-table>
    <Page v-if="paging" :total="paging.total" :page-size="paging.perPage" size="small" style="margin-top: 6px;"
      @on-change="change" />
  </div>
</template>
<script>
//import TablePlotRender from "component/board/board/cardList/TablePlotRender";
import { subNodeContainer } from "@/api/frame";
import { useDatapoolStore } from '@/stores/datapool';
import { useNodeStore } from '@/stores/node';

import * as moment from 'dayjs';
export default {
  props: {
    scene: String,
    coordinate: Array,
    curNode: Boolean,
    filter: Object,
    query: Object,
    sort: Object,
    valueVariety: Array,
    levelVariety: Array,
    categoryVariety: String,
  },
  components: {
    //TablePlotRender
  },
  data() {
    return {
      nodes: [],
      activeCell: {
        col: null,
        row: null,
        context: {},
      },
      activeRowIndex: null,
      activeContext: {
        id: undefined,
        position: undefined,
      },
      editingCell: {
        col: null,
        row: null,
      },
      columns: [
        {
          title: "类型",
          key: "variety",
          width: 80,
          slot: "variety",
        },
        {
          title: "标识",
          key: "name",
          slot: "name",
        },
        {
          title: "操作",
          key: "action",
          width: 130,
          slot: "action",
          align: "right",
        },
      ],
      editingValue: null,
      collapseState: {},
      curContext: undefined,
      cardViewScene: undefined,
      dispatchedInitPositions: [],
      flags: [],
      current: 0,
      paging: {
        perPage: 10,
        total: 0,
      },
    };
  },
  methods: {
    entreRow(row) {
      const variety = row.node.variety;
      if (this.levelVariety) {
        let item = this.levelVariety.find((n) => {
          return n.label === variety;
        });
        if (item) {
          this.$emit("categoryed", {
            row,
            value: row.node.payload[item.value],
          });
        }
      }
    },
    selectRow(row) {
      const variety = row.node.variety;
      if (this.levelVariety) {
        let item = this.levelVariety.find((n) => {
          return n.label === variety;
        });
        if (item) {
          this.$emit("categoryed", {
            row,
            value: row.node.payload[item.value],
          });
        }
      }

      let item = this.valueVariety.find((n) => {
        return n.label === variety;
      });
      if (item) {
        this.$emit("selected", row);
      }
    },
    filterValue(row) {
      const variety = row.node.variety;
      const levelItem = this?.levelVariety?.find((n) => {
        return n.label === variety;
      });
      if (levelItem) {
        return row.node.payload[levelItem.value];
      } else {
        const valueItem = this.valueVariety.find((n) => {
          return n.label === variety;
        });
        if (valueItem) {
          return row.node.payload[valueItem.value];
        }
      }
    },
    filterVariety(row) {
      const scheme = this.datapoolStore.getDatapoolByIndex('schemes', row.node.variety, 'variety')

      // const scheme = this.$store.state.datapool.schemes.find((n) => {
      //   return n.variety === row.node.variety;
      // });
      console.log("scheme.name", scheme, row.node.variety);
      return scheme.name;
    },
    async getNodes(current = 0) {
      if (!this.coordinate) {
        return;
      }
      const valueVarietys = this.valueVariety.map((n) => {
        return n.label;
      });
      const levelVarietys = this.levelVariety.map((n) => {
        return n.label;
      });
      const varietys = valueVarietys.concat(levelVarietys);
      const res = await subNodeContainer({
        position: {
          frameId: this.nodeStore.frame._id,
          coordinate: this.coordinate,
        },
        paging: {
          perPage: 10,
          current: current,
        },
        filter: this.filter,
        query: {
          variety: { $in: varietys },
        },
        // sort,
        // depth,
      });
      //res.data.data.records.filter
      this.nodes = res.data.data.records;

      this.paging = res.data.data.paging;
      this.current = this.paging?.current;
    },
    change(current) {
      this.getNodes(current - 1);
    },
  },
  computed: {


  },
  watch: {
    coordinate() {
      this.getNodes();
    },
    filter() {
      this.getNodes();
    }
  },
  filters: {
    timeFormat(timestamp) {
      return moment(timestamp).format("y-M-D k:mm");
    },
  },
  mounted() {
    this.getNodes();
  },
  setup() {
    const nodeStore = useNodeStore()
    const datapoolStore = useDatapoolStore()
    return {
      nodeStore,
      datapoolStore
    }
  }
};
</script>

<style lang="less" scoped>
.table-input {
  margin-left: -9px;
}


.center-text {
  text-align: center;
}

.select-btn {
  display: none;
}

.ivu-table-row-hover .select-btn {
  display: inline-block;
}

.ivu-table-cell {
  padding: 0 6px;
}
</style>
