// Pinia Store
import { defineStore } from "pinia";

export const useDatapoolStore = defineStore("datapool", {
  //  persist: true,

  // 转换为函数
  state: () => ({
    schemes: [],
    plots: [],
    forms: [],
    tables: [],
    states: [],
    handlers: [],
    schedules: [],
  }),
  getters: {
    getDatapoolByIndex:
      (state) =>
      (collection, index, indexKey = "scene") =>
        state[collection].find((item) => item[indexKey] === index),
  },
  actions: {
    initDatapool() {
      this.schemes = [];
      this.plots = [];
      this.forms = [];
      this.tables = [];
      this.states = [];
      this.handlers = [];
      this.schedules = [];
    },
    insertDatapool({ collection, item }) {
      let index;
      switch (collection) {
        case "schemes":
          index = "variety";
          break;
        case "plots":
          index = "scene";
          break;
        case "forms":
          index = "scene";
          break;
        case "tables":
          index = "scene";
          break;
        case "states":
          index = "key";
          break;
        case "handlers":
          index = "route";
          break;
        case "schedules":
          index = "route";
          break;
      }
      if (!Array.isArray(item)) {
        item = [item];
      }
      for (let value of item) {
        console.log("collection", collection);
        const curIndex = this[collection].findIndex(
          (n) => n[index] === value[index]
        );
        if (curIndex === -1) {
          this[collection].push(value);
        } else {
          this[collection].splice(curIndex, 1, value);
        }
      }
    },
    deleteDatapool({ collection, id }) {
      const curFormIndex = this[collection].findIndex((n) => {
        return n._id === id;
      });
      this[collection].splice(curFormIndex, 1);
    },
  },
});
