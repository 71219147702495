<template>
  <div>

    <!-- <editor class="card-editor" v-model="modelValue" :cache="false" :menus="[
      'bold', // 粗体
      'fontSize', // 字号
      'italic', // 斜体
      'underline', // 下划线
      'foreColor', // 文字颜色
      'link', // 插入链接
      'justify', // 对齐方式
      'image', // 插入图片
      'code', // 插入代码
      'undo', // 撤销
      'redo', // 重复
    ]"></editor> -->
    <RichEditor v-model="modelValue" />
  </div>
</template>

<script>
import RichEditor from "@/components/common/richEditor/RichEditor.vue";
import { getFun, setFun } from '../modelValue.js';

export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["formModel", "keyPath"],
  components: {
    RichEditor
  },
  computed: {
    modelValue: {
      get: function () {
        return getFun(this.formModel, this.inputParams, this.keyPath)
      },
      set: function (newValue) {
        return setFun(this.formModel, this.inputParams, newValue, this.keyPath)
      },
    },
  },
  mounted() {
    // this.formModel()[this.inputParams.propertyId];
  },
};
export const isStringType = true;
</script>
<style scoped>
.description-text {
  margin-bottom: 16px;
  width: 100%;
  min-height: 150px;

  max-height: 500px;
  overflow: auto;
}

.card-editor {
  background: #fff;
}
</style>
