import axios from "axios";

export const createTag = (params) =>
  axios.post("/preset/graph/createTag", params);

export const descTag = (params) => axios.post("/preset/graph/descTag", params);

export const alterTag = (params) =>
  axios.post("/preset/graph/alterTag", params);

export const subNode = (data) => axios.post("/preset/graph/subNode", data);
export const updateNode = (data) =>
  axios.post("/preset/graph/updateNode", data);

export const deleteEdge = (params) =>
  axios.post("/preset/graph/deleteEdge", params);

export const createIndex = (params) =>
  axios.post("/preset/graph/createIndex", params);

export const rebuildIndex = (params) =>
  axios.post("/preset/graph/rebuildIndex", params);
export const describeIndex = (params) =>
  axios.post("/preset/graph/describeIndex", params);
export const exportAll = (params) =>
  axios.post("/preset/graph/exportAll", params);
export const importAll = (params) =>
  axios.post("/preset/graph/importAll", params);

export const createBackup = (params) =>
  axios.post("/preset/create/backup", params);

export const lookupBackup = (params) =>
  axios.post("/preset/lookup/backup", params);
export const viewBackup = (params) => axios.post("/preset/view/backup", params);
export const removeAloneVertex = (params) =>
  axios.post("/preset/remove/aloneVertex", params);
