<template>
  <div class="grid-value" :class="inputParams.class" v-html="formModelEntity?.[inputParams.prop]">
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["_curNode", "curNode", "preNode"],
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this.curNode()?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        let edge = this.curNode().edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })
        return edge?.props
      }
    },
    unit() {
      return this.inputParams?.unit
    }
  },
  components: {},
};
export const isStringType = true;
</script>
<style lang="less" scoped>
@import "../cardGrid.less";
</style>
