<template>
  <div class="d-flex">
    <div v-if="inputParams?.pushable">
      <Button @click="addItem()" size="small" class="re-small add-btn" type="primary">
        增加
      </Button>
    </div>
    <div style="flex: 1;">
      <div v-for="(item, i) in modelValue" :key="i" class="d-flex edge-item">
        <EdgeItem :index="i" v-if="item" :key="i">
          <slot></slot>
        </EdgeItem>
        <div class="delete-btn" v-if="inputParams?.pushable">
          <Button @click="handleRemove(i)" size="small" class="re-small " ghost type="error">
            删除
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import EdgeItem from './EdgeItem';

export default {
  props: {
    inputParams: Object,
  },
  inject: ["formModel"],
  provide: function () {
    return {
      formModel: computed(() => this.modelValue) //this.modelValue

    };
  },
  components: { EdgeItem },
  data() {
    return {
      // modelValue: [],
    };
  },
  methods: {
    addItem() {
      console.log("this.modelValue", this.modelValue, this.inputParams.variety)
      //this.inputParams.variety = 
      let edge = []
      // edge.push({
      //   name: this.inputParams.variety
      // })
      this.inputParams.variety?.map(variety => {
        edge.push({
          name: variety
        })
      })

      this.modelValue.push({ edge })
      console.log("this.modelValue edge", this.modelValue)

    },
    handleRemove(index) {
      console.log("this.modelValue", this.modelValue, index, this.modelValue)
      this.modelValue.splice(index, 1);
      console.log("this.modelValue2", this.modelValue, index, this.modelValue)

    },
  },
  computed: {
    modelValue: {
      get: function () {
        // if (this.inputParams.varietyType == 'extend') {
        if (!this.formModel?.extend) {
          this.formModel.extend = []
        }
        return this.formModel?.extend
        //  }
      },
      set: function (newValue) {
        // this.formModel[this.inputParams.index] = newValue;
        this.formModel.extend = newValue;
        // this.formModel[this.inputParams.index] = newValue;
      },
    },
  },
  watch: {
    modelValue(newValue) {
      console.log("newValue", newValue);
    },
  },
  mounted() {
    //  this.modelValue = this.formModel?.extend

    // if (!this.inputParams?.pushable) {
    //   this.modelValue.push({})
    // }
  },
};
</script>
<style lang="less" scoped>
.edge-item {
  .delete-btn {
    visibility: hidden;
  }
}

.edge-item:hover {
  .delete-btn {
    visibility: visible
  }
}
</style>