<template>
  <div class="grid-value" :class="{
    'grid-value': true,
    'x-direction': inputParams.direction == 'x',
    'y-direction': inputParams.direction == 'y'
  }">
    <div class="grid-array-value" v-for="(item, index) in modelValue" :key="index">
      <span class="array-value-sort"> {{ index + 1 }}、 </span>
      <span> {{ item }} </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["_curNode", "preNode"],
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this._curNode?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        let edge = this._curNode.edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })
        return edge?.props
      }
    },
    modelValue() {
      let json = this.formModelEntity?.[this.inputParams.prop]
      try {
        return JSON.parse(json)

      } catch (err) {
        return []
      }
    }
  },
  mounted() { },
};
</script>
<style lang="less" scoped>
@import "../cardGrid.less";

.x-direction {
  display: flex;
  gap: 16px;
}

.y-direction {
  display: block;
}

.array-value-sort {
  color: #0036839c;
  font-size: 12px;
  margin-right: 4px
}
</style>
