<template>
  <div>
    <FormItem label="列名（一行一个）">
      <Input type="textarea" v-model="tableCols" :rows="5" :disabled="disabled" />
    </FormItem>
  </div>
</template>

<script>
//import { FormItem, Input } from "view-ui-plus";
export default {
  // components: {
  //   // Checkbox,
  //   FormItem,
  //   "i-input": Input,
  // },
  props: {
    item: Object, disabled: Boolean,
  },
  computed: {
    tableCols: {
      get() {
        let orig = this.item.tableCols;
        return orig.join("\n");
      },
      set(value) {
        this.item.tableCols = value.trim().split("\n");
      },
    },
  },
};
</script>

<style lang="less" scoped></style>
