import OdInput from "@/components/common/odInput/OdInput.vue";
import RenderedForm from "@/components/from/RenderedForm";
import RenderedInput from "@/components/from/RenderedInput";
import CardAuth from "@/components/plot/CardAuth.vue";
import CardCreate from "@/components/plot/CardCreate.vue";
import CardSetting from "@/components/plot/CardSetting.vue";
import Chart from "@/components/plot/Chart.vue";
import Gcharts from "@/components/plot/Gcharts.vue";

import BlockList from "@/components/plot/BlockList.vue";
import CreaterDiversity from "@/components/plot/CreaterDiversity.vue";
import FormWrap from "@/components/plot/FormWrap.vue";
import InviteUser from "@/components/plot/InviteUser.vue";
import LevelList from "@/components/plot/LevelList.vue";
import Map from "@/components/plot/Map.vue";
import ModalBtn from "@/components/plot/ModalBtn.vue";
import VideoPlayer from "@/components/plot/VideoPlayer.vue";
import GridPlotRender from "@/components/plotRender/GridPlotRender.js";
import TableRender from "@/components/table/TableRender.vue";

import RenderedGrid from "@/components/grid/RenderedGrid";
import RenderedGridItem from "@/components/grid/RenderedGridItem";
import ExtendList from "@/components/plot/ExtendList";
import G2charts from "@/components/plot/G2charts";
import Gantt from "@/components/plot/Gantt";

//import PlotRender from './PlotRender.js'
import { eval as expEval, parse as expParse } from "expression-eval";
import * as ViewUIPlusComponents from "view-ui-plus";
import { defineAsyncComponent, ref } from "vue";
const PlotRender = defineAsyncComponent(() => import("./PlotRender.js"));
const components = {
  LevelList,
  InviteUser,
  CardCreate,
  GridPlotRender,
  TableRender,
  CardSetting,
  CardAuth,
  OdInput,
  RenderedInput,
  RenderedForm,
  Chart,
  Gcharts,
  FormWrap,
  VideoPlayer,
  ModalBtn,
  Map,
  CreaterDiversity,
  BlockList,
  RenderedGrid,
  RenderedGridItem,
  PlotRender: PlotRender,
  G2charts,
  ExtendList,
  Gantt,
  ...ViewUIPlusComponents,
};

const plotRender = (h, node, vm, temp) => {
  if (!node) {
    return;
  }
  //事件机制
  let eventObj = {};
  if (node.action && !node.plot) {
    return vm.actionDeal(node);
  }
  if (node.events) {
    eventObj = vm.eventDeal(node);
  }
  const assignedNode = mapAssign(node, vm.context, temp); // 被赋值后的节点
  const params = assignedNode.params || {};
  const props = assignedNode.props || {};
  let type = node.plot;
  const has = node.plot in components;

  if (has) {
    type = components[type];
  }
  // let contextValue = _.clone(vm.context.value)
  return h(
    type,
    { ...params, ...params?.props, ...eventObj, ...props },
    { default: () => renderLast(h, assignedNode, vm, vm.context, temp) }
  );
};
const renderLast = (h, node, vm, context, temp = {}) => {
  if ("foreach" in node) {
    const forArray = evalExpression(node.foreach, context);
    if (!forArray) {
      return;
    }

    if (Array.isArray(forArray)) {
      const { forValue } = node;
      const { forKey } = node;
      const eachNode = { ...node, foreach: true };

      const tempValue = context[forValue];
      const tempKey = context[forKey];
      //  console.log("forValue", forValue, forKey, forArray);

      const innerPlots = [];
      for (let i = 0, l = forArray.length; i < l; i++) {
        temp[forValue] = forArray[i];
        temp[forKey] = i;
        innerPlots.push(renderLast(h, eachNode, vm, context, temp));
      }
      context[forValue] = tempValue;
      context[forKey] = tempKey;
      return innerPlots;
    }
  }
  if (node.sub && node.sub?.length != 0) {
    if (Array.isArray(node.sub) && node.sub.length != 0) {
      return node?.sub?.map((n) => plotRender(h, n, vm, temp));
    }
    return [plotRender(h, node.sub, vm, temp)];
  }
  return node?.text;
};
const mapAssign = (node, context, temp) => {
  let obj;
  if (Array.isArray(node)) {
    obj = Object.assign([], node);
  } else {
    obj = { ...node };
  }
  for (const i in obj) {
    const curProp = obj[i];
    if (i === "sub") {
      continue;
    }
    if (i === "action") {
      continue;
    }
    if (typeof curProp === "object" && curProp !== null) {
      obj[i] = mapAssign(curProp, context, temp);
    } else if (typeof curProp === "string") {
      if (curProp.charAt(0) == "#") {
        //表达式解析
        obj[i] = ref(
          evalExpression(curProp, { ...context.value, ...temp })
        ).value;
      } else if (curProp.charAt(0) == "`") {
        //表达式代码解析
        obj[i] = ref(evalCode(curProp, { ...context.value, ...temp })).value;
      }
    }
  }
  return obj;
};
const evalExpression = (expr, context) => {
  try {
    if (typeof expr !== "string") {
      return expr;
    }
    const matches = /^\#\{(.+)\}$/.exec(expr);
    if (!matches) {
      return expr;
    }
    expr = matches[1];
    return expEval(expParse(expr), context);
  } catch (err) {
    // console.error(err);
    return "";
  }
};
const evalCode = (code, context) => {
  const regex = /^`(.*)`$/;
  const match = code.match(regex);

  if (match) {
    const code = match[1];
    const restrictedEval = new Function("$", code);
    return restrictedEval(context);
  } else {
    return null;
  }
};
export { evalExpression, mapAssign, plotRender, renderLast };
