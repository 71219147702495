// Pinia Store
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  persist: true,

  // 转换为函数
  state: () => ({
    _id: null,
    username: "",
    account: "",
    avatar: "",
    tel: "",
    gender: "",
    access: "",
  }),
  getters: {
    // // 不在需要 firstName getter，移除
    // fullName: (state) => `${state.firstName} ${state.lastName}`,
    // loggedIn: (state) => state.userId !== null,
    // // 由于使用了 `this`，必须定义一个返回类型
    // fullUserDetails (state) {
    //   // 导入其他 store
    //   const authPreferencesStore = useAuthPreferencesStore()
    //   const authEmailStore = useAuthEmailStore()
    //   return {
    //     ...state,
    //     // `this` 上的其他 getter
    //     fullName: this.fullName,
    //     ...authPreferencesStore.$state,
    //     ...authEmailStore.details
    //   }
    //   // 如果其他模块仍在 Vuex 中，可替代为
    //   // return {
    //   //   ...state,
    //   //   fullName: this.fullName,
    //   //   ...vuexStore.state.auth.preferences,
    //   //   ...vuexStore.getters['auth/email'].details
    //   // }
    // }
  },
  actions: {
    // 没有作为第一个参数的上下文，用 `this` 代替
    async loadUser(id) {
      if (this.userId !== null) throw new Error("Already logged in");
      const res = await api.user.load(id);
      this.updateUser(res);
    },
    updateUser(user) {
      this._id = user._id;
      this.username = user.username;
      this.avatar = user.avatar;
      this.gender = user.gender;
      this.account = user.account;
    },
    // 使用 `$reset` 可以轻松重置 state
    clearUser() {
      this.$reset();
    },
  },
});
