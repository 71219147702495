<script>
import ViewUIPlus from "view-ui-plus";

export default {
    extends: ViewUIPlus.InputNumber,
    name: 'InputNumber',
    props: {

        modelValue: {
            type: Number,
            default: null
        },

    },
};
</script>