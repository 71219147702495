<template>
  <div class="d-flex">
    <div style="margin-right: 4px;">
      <Button @click="addItem()" size="small" class="re-small add-btn" type="primary">
        增加
      </Button>
    </div>
    <div style="flex: 1;margin-left: 6px;">
      <div v-for="(item, i) in tempArr" :key="i" class="d-flex edge-item">
        <i-input v-model="tempArr[i]" :type="inputParams.inputType" :autosize="inputParams.autosize" />

        <div class="delete-btn">
          <Button @click="handleRemove(i)" size="small" class="re-small " ghost type="error">
            删除
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFun, setFun } from '../modelValue.js';

import ListItem from './ListItem';
export default {
  props: {
    inputParams: Object,
  },
  inject: ["formModel", "keyPath"],
  provide: function () {
    return {
      formModel: () => {
        return this.tempArr;
      }
    };
  },
  components: { ListItem },
  data() {
    return {
      tempArr: [],
    };
  },
  methods: {
    addItem() {
      console.log("this.modelValue", this.modelValue)
      // if (!this.modelValue) {
      //   this.modelValue = []
      // }
      this.tempArr.push('')
      console.log("this.modelValue edge", this.modelValue)
    },
    handleRemove(index) {
      this.tempArr.splice(index, 1);
    },
    setModel(value) { }
  },
  computed: {
    modelValue: {
      get: function () {
        let text = getFun(this.formModel, this.inputParams, this.keyPath)
        // let obj = []
        // if (text) {
        //   obj = JSON.parse(text)
        // }
        //  console.log('text', text)
        return text
      },
      set: function (newValue) {
        // const obj = JSON.stringify(newValue)
        // console.log("obj", obj)
        return setFun(this.formModel, this.inputParams, newValue, this.keyPath)
      },
    },
  },
  watch: {
    tempArr: {
      handler(newValue, oldValue) {

        this.modelValue = JSON.stringify(newValue)
        console.log("newValue", newValue, this.modelValue);
      },
      deep: true
    },
  },
  mounted() {
    console.log("this.modelValue", this.modelValue);
    console.log(this.modelValue);

    if (!this.modelValue) {
      this.modelValue = "[]"
    }
    try {
      let noSpacesStr = this.modelValue.replace(/\s+/g, '');

      this.tempArr = JSON.parse(noSpacesStr)
      console.log("this.tempArr", this.tempArr);
    } catch (e) {
      console.error(e)
    }
  },
};
</script>
<style lang="less" scoped>
.delete-btn {
  visibility: hidden;
}

.edge-item:hover {
  .delete-btn {
    visibility: visible
  }
}
</style>