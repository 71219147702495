<template>
  <div class="d-flex" style="align-items: center;gap: 8px;flex-wrap: wrap;">
    <div v-for="(file, i) in files" :key="i">
      <file :inputParams="inputParams" :index="i"></file>
    </div>
  </div>
</template>

<script>
import file from './file.vue';
export default {
  props: {
    inputParams: Object,
  },
  components: {
    file
  },
  computed: {
    formModelEntity() {
      const entity = this._curNode?.node?.payload;
      if (entity) {
        return entity;
      } else {
        return {};
      }
    },
    files() {

      return this.formModelEntity?.[this.inputParams.propertyId]
    },
  },
  inject: ["_curNode"],
};
</script>
<style lang="less" scoped>
@import '../cardGrid.less';

.item-pic {
  width: 64px;
  flex: 0 0 64px;
  text-align: center;
}

.file-icon {
  line-height: 28px;
  font-size: 20px;
}
</style>
