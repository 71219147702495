<template>
  <div>
    <template v-for="(card, index) in extendNodes">


      <Poptip v-if="poptipSceneSelect(card)" trigger="hover" placement="bottom-start" style="width: 100%;">
        <div :key="index" v-if="inItemVariety(card)" @click="showView(index)" @click.right="showViewRight(index)"
          :class="[
            'card-item-wrap', 'd-flex',
          ]">
          <PlotRender v-if="card" :scene="itemSceneSelect(card)" :params="{ _curNode: card, preNode: node }"
            :key="extendNodes[index].vid" style="flex: 1 0 auto;" />
        </div>
        <template #content>
          <div style="width: 200px;">
            <PlotRender :scene="poptipSceneSelect(card)" :params="{ _curNode: card, preNode: node }" :key="card.vid">
            </PlotRender>
          </div>
        </template>
      </Poptip>
      <template v-else>
        <div :key="index" v-if="inItemVariety(card)" @click="showView(index)" @click.right="showViewRight(index)"
          :class="[
            'card-item-wrap', 'd-flex',
          ]">
          <PlotRender v-if="card" :scene="itemSceneSelect(card)" :params="{ _curNode: card, preNode: node }"
            :key="extendNodes[index].vid" style="flex: 1 0 auto;" />
        </div>
      </template>
    </template>
    <Modal v-model="showCardModal" :styles="cardModalStyle" footer-hide>
      <div :style="{ minHeight: '480px' }" v-if="showCardModal">
        <PlotRender :scene="cardSceneSelect(extendNodes[curCardIndex])"
          :params="{ _curNode: extendNodes[curCardIndex], preNode: node }" :key="extendNodes[curCardIndex].vid">
        </PlotRender>
      </div>
      <div class="card-tool-bar">
        <ButtonGroup>
          <Button @click="lastCard()">
            <Icon type="ios-arrow-back"></Icon>
            上一卡
          </Button>
          <Button @click="nextCard()">
            下一卡
            <Icon type="ios-arrow-forward"></Icon>
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  </div>
</template>
<script>
import { useNodeStore } from '@/stores/node';
import matches from "lodash-es/matches";

export default {
  name: "ExtendList",
  props: {
    node: Object,
    nodes: Array,
    filter: {
      type: Object,
      default: function () {
        return {
          edgeType: [],
          type: 1,
          where: {}
        };
      },
    },
    itemScenes: Array,
    cardScenes: Array,
    poptipScenes: Array,
    clickCardModal: {
      type: Boolean,
      default: true,
    },
    cardModalStyle: {
      type: Object,
      default: function () {
        return {
          top: "100px",
          width: "720px",
          minWidth: "1020px",
          maxWidth: "1600px",
        };
      },
    },
    perPage: { type: Number, default: 20 },

  },
  data() {
    return {
      curCardIndex: -1,
      showCardModal: false,
      page: 0,
      curIndex: "",
      paging: {},
      showSub: true,
      current: 1,
      first: true
    };
  },
  methods: {

    onSelect(card) {
      this.$emit("select", card)
    },
    showView(index) {
      //this.$emit("select", card)

      if (!this.clickCardModal) return;
      this.curCardIndex = index;

      this.showCardModal = true;
    },
    lastCard() {
      if (this.curCardIndex !== 0) {
        this.curCardIndex--;
      }
    },
    nextCard() {
      if (this.curCardIndex !== this.extendNodes?.length - 1) {
        this.curCardIndex++;
        console.log("this.curCardIndex", this.curCardIndex)
      }
    },
    showViewRight(index) {
      document.oncontextmenu = function (e) {
        return false;
      };
      this.curCardIndex = index;
      this.showCardModal = true;
    },
    itemSceneSelect(card) {
      const item = this.itemScenes?.find((n) => {
        return n.variety in card?.tag
      });
      return item.scene
    },
    cardSceneSelect(card) {
      const cardModal = this.cardScenes?.find((n) => {
        return n.variety in card?.tag
      });
      return cardModal.scene
    },
    poptipSceneSelect(card) {
      const cardModal = this.poptipScenes?.find((n) => {
        return n.variety in card?.tag
      });
      return cardModal?.scene
    },
    inItemVariety(card) {
      if (!card?.tag) return false;
      const item = this.itemScenes?.find((n) => {
        if (n.variety in card?.tag) {
          return true
        }
      });
      if (!item) {
        return false;
      } else {
        return true;
      }
    },

    async fetchContext(current = 1, step = 0) {
      try {
        this.loading = true;

        this.$emit("mounted", {
          paging: {
            perPage: step == 0 ? this.perPage : step,
            current: current - 1
          }
        });

      } catch {
        (error) => {
          console.error(error)
          if (error.response) {
            this.$Message.error(`${error.response.data.message}`)
          } else {
            this.$Message.error('系统错误!')
          }
        }
      } finally {
        this.loading = false;

      }
    },
  },
  computed: {
    extendNodes() {
      if (this.nodes) {
        return this.nodes
      }
      let edgeTypes = this.filter.edgeType
      if (typeof (edgeTypes) === 'string') {
        edgeTypes = [edgeTypes]
      }

      let nodeEdges, edgeKey
      if (this.filter.type === 1) {
        nodeEdges = this.node?.edge
        edgeKey = 'src'
      } else if (this.filter.type === -1) {
        nodeEdges = this.node?.reEdge
        edgeKey = 'dst'
      }
      let edges = nodeEdges?.filter(e => {
        let edgeWhereRes = true
        if (this.filter.where) {
          console.log("this.where", this.filter.where)
          edgeWhereRes = matches(this.filter.where)(e?.props);
          console.log("edgeWhereRes", edgeWhereRes, e?.props)

        }
        return edgeTypes.includes(e.name) && edgeWhereRes
      })
      let nodes = edges?.map(edge => {
        return this.nodeStore.nodes.find(node => node.vid == edge[edgeKey])
      })
      return nodes

      // if (this.filter.type === 1) {
      //   let edges = this.node.edge.filter(e => {
      //     let edgeWhereRes = true
      //     if (where) {
      //       edgeWhereRes = matches(where)(e?.props);
      //     }
      //     return edgeTypes.includes(e.name) && edgeWhereRes
      //   })
      //   let nodes = edges.map(edge => {
      //     return this.nodeStore.nodes.find(node => node.vid == edge.src)
      //   })
      //   return nodes
      // } else if (this.filter.type === -1) {
      //   let edges = this.node.reEdge.filter(e => edgeTypes.includes(e.name))
      //   let nodes = edges.map(edge => {
      //     return this.nodeStore.nodes.find(node => node.vid === edge.dst)
      //   })
      //   return nodes
      // }



    },

  },

  async mounted() {

  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style lang="less" scoped>
@import url("@/styles/board.less");

.sub-level {
  margin-left: 24px;
  margin-bottom: 1px;
}

.list-fold {
  position: relative;
  right: 1px;
  height: 100%;
  bottom: 0px;
  padding: 3px;
  background: #f5f5f5;
  color: #1b3d6b;
}

.show-sub {
  position: absolute;
  right: 0;
  top: -31px;
}
</style>
