<template>
  <div :id="id"></div>
</template>
<script>
import { useNodeStore } from '@/stores/node';
import * as echarts from 'echarts';
import * as _ from 'lodash';;

export default {
  props: {
    option: Object,
    //title: String,
    id: String,
    branch: String,
    params: { type: Object }

  },
  inject: {
    curNode: 'curNode',
  },
  components: {},
  data() {
    return {
      chart: {},
      _option: {},
      chartData: {},
      unwatch: undefined
    };
  },
  computed: {
    _params: {
      get() {
        for (let key in this.params) {
          const item = this.params[key]
          const branchId = item.branch
          const path = item.path
          //  const branch = this.nodeStore.branch[branchId]
          let branch = this.nodeStore.getBranch({ branchId })
          if (!branch) {
            branch = []
          }

          this.chartData[key] = branch?.map(n => {
            return _.get(n, path);
          })
          console.log("this.chartData[key]", this.chartData[key])
        }
        return this.chartData
      },
      set() { }
    }
  },
  watch: {
    branch: {
      handler(newValue, oldValue) {
        /// this.renderChart()
        if (typeof (this.unwatch) == 'function') {
          this.unwatch()
        }

        this.unwatch = this.$watch(`nodeStore.branch.${newValue}`, (newVal) => {
          this.renderChart()
          console.log('nodeStore.branch newVal', newVal)
        }, { deep: true })
      },
      deep: true
    },
  },
  mounted() {
    // if (!this?.option?.yAxis) {
    //   this.option.yAxis = {}
    // }
    this.chart = echarts.init(document.getElementById(this.id));
    this.renderChart()

    // this.chart.setOption({
    //   title: {
    //     text: 'ECharts 入门示例'
    //   },
    //   tooltip: {},
    //   xAxis: {
    //     data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
    //   },
    //   yAxis: {},
    //   series: [
    //     {
    //       name: '销量',
    //       type: 'bar',
    //       data: [5, 20, 36, 10, 10, 20]
    //     }
    //   ]
    // });
  },
  methods: {
    async renderChart() {
      // this.makeChartData()
      this._option = this.makeOption(this.option)
      console.log("_option", this._option)
      await this.$nextTick()

      // this.chart = echarts.init(document.getElementById('main'));
      console.log("_optionxAxis.data", this._option.xAxis.data)
      // this._option.xAxis.data = [
      //   "导航栏",
      //   "顶部栏",
      //   "底部栏",
      //   "备案栏"
      // ]
      //  await this.$nextTick()
      this.chart.setOption(this._option)
      // if (this._option.xAxis.data) {
      //   this.chart.setOption(Aoption)

      // }
      //  await this.$forceUpdate()
      // this.chart.setOption({
      //   "title": {
      //     "text": "下级节点数量"
      //   },
      //   "xAxis": {
      //     "data": [
      //       "导航栏",
      //       "顶部栏",
      //       "底部栏",
      //       "备案栏"
      //     ]
      //   },
      //   "yAxis": { "title": "yAxis" },
      //   "series": [
      //     {
      //       "name": "销量",
      //       "type": "bar",
      //       "data": [
      //         4,
      //         0,
      //         0,
      //         0
      //       ]
      //     }
      //   ]
      // })
    },
    makeChartData() {
      console.log("this.params", this.params)
      for (let key in this.params) {
        const item = this.params[key]
        const branchId = item.branch
        const path = item.path
        //  const branch = this.nodeStore.branch[branchId]
        const branch = this.nodeStore.getBranch({ branchId })
        // console.log("branchId", branchId, path, item)
        // console.log("branch", branch, key)
        // console.log("branch2", this.nodeStore.branch)
        // console.log("branch3", this.nodeStore.branch["board_info_block-info_block0_2_0"])

        this.chartData[key] = branch?.map(n => {
          return _.get(n, path);
        })
        console.log("this.chartData[key]", key, this.chartData[key])
      }
    },
    makeOption(option) {
      let obj = {}
      if (Array.isArray(option)) {
        obj = []
      }
      if (typeof option == 'object') {
        for (let key in option) {
          obj[key] = this.makeOption(option[key])
        }
        return obj
      } else if (typeof (option) == 'string') {
        const regExp = /^B{(.*)}$/g;

        //let res = regExp.match(option)
        let res = option.match(regExp)
        if (res) {
          let str = res[0]

          str = str.replace(/^B{/, '')
          str = str.replace(/}$/, '')
          // console.log('str', str)
          if (str) {
            const key = str
            return this._params[key]
            // return this.chartData[key]
          }
        }

      }
      return option
    }
  },
  setup() {
    const nodeStore = useNodeStore()

    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less"></style>
