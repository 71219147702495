<template>
  <FormItem :label="inputParams?.label" :label-width="labelWidth" class="rendered-item">
    <component :is="inputComp" :inputParams="inputParams" @submit="onSubmit" @setFormModel="setFormModel">
      <slot></slot>
    </component>
  </FormItem>
</template>
<script>
import inputs from "./inputs/input.js";
// import { getRules } from "./utils";
export default {
  components: {
    // FormItem,
  },
  inject: [
    "labelWidth", 'formModel'
  ],

  props: {
    // form: Object,
    inputParams: Object,
    selected: Boolean,
    type: {
      type: String,
      default: "single",
      validator(value) {
        return ["single", "array"].indexOf(value) !== -1;
      },
    },
    index: Number,
    list: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    inputComp() {
      // console.log(" this.inputParams", inputs[this.inputParams.type], this.inputParams);
      // console.log("inputs", inputs);
      // console.log("inputs3", inputs[this.inputParams.type]?.default);
      return inputs[this.inputParams.type];
    },
    rules() {
      return { required: true, message: this.inputParams?.label + ' can not be empty', trigger: 'blur' }
      // return null;
      // return getRules(this.inputParams);
    },
    isNotInput() {
      return !!inputs[this.inputParams.type].isNotInput;
    },
    itemProp() {
      const varietyType = this.inputParams.varietyType;
      const variety = this.inputParams.variety;
      const prop = this.inputParams.prop;
      if (!varietyType || !variety || !prop) {
        return null
      }

      let formModels = this.formModel()
      if (formModels) {
        console.log('itemProp', formModels, `${varietyType}.${variety}.${prop}`)
        return `${varietyType}.${variety}.${prop}`

      } else {
        return null
      }

      //return `${varietyType}.${variety}.${prop}`;

      // if (this.type === "single") {
      //   return this.inputParams.index;
      // } else if (this.type == "array") {
      //   return `items.${this.index}.${this.inputParams.id}`;
      // } else {
      //   return null;
      // }
    },
    // itemForm() {
    //   if (this.type === "single") {
    //     return this.formModel;
    //   } else if (this.type == "array") {
    //     return this.formModel.items[this.index];
    //   }
    // },
  },
  methods: {
    onSubmit(card) {
      //  console.log("submit", card)
      this.$emit("submit", card);
    },
    setFormModel(model) {
      // console.log("setFormModel1", this.formModel(),model);
      this.$parent.$emit("setFormModel", model);
    },
  },
  mounted() { },
};
</script>
<style scoped>
.rendered-item {
  padding: 0 0;
  flex: 0 0 auto;
  text-align: left;
  /* padding-right: 12px; */
}
</style>
