<template>
  <!-- <component :is="comp"
             v-model="formModel()[inputParams.propertyId]"
             :type="inputParams.innerType"
             placeholder="清选择..."
             :disabled="disabled"></component> -->
  <div class="grid-value grid-time">
    {{ timeFormat(formModelEntity?.[inputParams.prop]) }}
    <!-- {{ formModelEntity[inputParams.propertyId] }} -->
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { DatePicker, TimePicker } from "view-ui-plus";

export default {
  components: {
    DatePicker,
    TimePicker,
  },
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["_curNode"],
  methods: {
    timeFormat(property) {
      let date = property

      if (typeof (property) !== 'string' && typeof (property) !== 'number' && typeof (property) !== 'undefined') {
        if (property?.hour) {
          date = new Date(
            property?.year,
            property?.month,  // 月份减去1，因为月份是从0开始的
            property?.day,
            property?.hour,
            property?.minute,
            property?.sec,
            Math.floor(property?.microsec / 1000) // 微秒转换为毫秒
          );
        } else {
          date = new Date(
            property?.year,
            property?.month,  // 月份减去1，因为月份是从0开始的
            property?.day,
          );
        }

      }
      let format = ""
      if (!this.inputParams?.format) {
        switch (this.inputParams?.innerType) {
          case "date": format = 'YYYY-MM-DD'; break
          case "time": format = 'hh:mm:ss'; break
          case "datetime": format = 'YYYY-MM-DD hh:mm:ss'; break
        }
      } else {
        format = this.inputParams.format
      }

      console.log('timeFormat', date, property)
      if (date) {
        return dayjs(date).format(format);
      } else {
        return "";
      }
    },
  },
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this._curNode?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        console.log("edge", this._curNode.edge, this.preNode, this.inputParams.variety)
        let edge = this._curNode.edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })
        console.log("edge2", edge)

        return edge?.props
      }
    },
    comp() {
      if (
        this.inputParams.innerType === "time" ||
        this.inputParams.innerType === "timerange"
      ) {
        return TimePicker;
      } else {
        return DatePicker;
      }
    },
  },
};
export function initItem(input) {
  input.innerType = "date";
  return input;
}
</script>
<style>
.grid-time {
  color: #436e65;
  font-size: 13px;
  letter-spacing: 1px;
}
</style>
