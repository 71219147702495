<template>
  <div class="d-flex">
    <div v-if="inputParams?.pushable">
      <Button @click="addItem()" size="small" class="re-small add-btn" type="primary">
        增加
      </Button>
    </div>
    <div style="flex: 1;">
      <!-- <slot></slot> -->
      <div v-for="(item, i) in tempArr" :key="i" class="d-flex edge-item">
        <ListItem :index="i" :key="i">
          <slot></slot>
        </ListItem>
        <div class="delete-btn" v-if="inputParams?.pushable">
          <Button @click="handleRemove(i)" size="small" class="re-small " ghost type="error">
            删除
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFun, setFun } from '../modelValue.js';

import ListItem from './ListItem';
export default {
  props: {
    inputParams: Object,
  },
  inject: ["formModel"],
  provide: function () {
    return {
      formModel: () => {
        return this.tempArr;
      }
    };
  },
  components: { ListItem },
  data() {
    return {
      tempArr: [],
    };
  },
  methods: {
    addItem() {
      console.log("this.modelValue", this.modelValue)
      if (!this.modelValue) {
        this.modelValue = []
      }

      //this.modelValue.splice(this.modelValue.length, 0, null)
      this.tempArr.push(null)
      console.log("this.modelValue edge", this.modelValue)
    },
    handleRemove(index) {
      console.log("this.modelValue", this.modelValue, index, this.modelValue)
      this.modelValue.splice(index, 1);
      console.log("this.modelValue2", this.modelValue, index, this.modelValue)
    },
  },
  computed: {
    modelValue: {
      get: function () {
        let value = getFun(this.formModel, this.inputParams)
        // if (!value) {
        //   return value = []
        // }
        // this.tempArr = value
        return value
      },
      set: function (newValue) {
        //newValue = JSON.stringify(newValue)
        return setFun(this.formModel, this.inputParams, newValue)
      },
    },
  },
  watch: {
    tempArr: {
      handler(newValue, oldValue) {
        this.modelValue = JSON.stringify(newValue)
        console.log("newValue", newValue, this.modelValue);
      },
      deep: true
    },

  },
  mounted() {
    if (!this.modelValue) {
      this.modelValue = "[]"
    }
    this.tempArr = JSON.parse(this.modelValue)
  },
};
</script>
<style lang="less" scoped>
.delete-btn {
  visibility: hidden;
}

.edge-item:hover {
  .delete-btn {
    visibility: visible
  }
}
</style>