<template>
  <div style="text-align: end;">
    <i-button :type="inputParams.buttonType ? inputParams.buttonType : 'text'" @click="handleReset"
      :loading="loadingSubmit">
      {{ inputParams.text ? inputParams.text : '重置' }}
    </i-button>
  </div>
</template>

<script>

export default {
  props: {
    inputParams: Object,
  },

  inject: {
    handleReset: 'handleReset',
    formModel: 'formModel',
    form: {
      default: () => {
        return undefined
      },
    },
  },
  data() {
    return {
      loadingSubmit: false
    }
  },
  mounted() { },
  methods: {
    reset() {
      this.$refs.iForm.resetFields()
    },
    setFormModel(model) {
      this.$parent.$emit('setFormModel', model)
    },
  },
};
export const isStringType = true;
</script>
