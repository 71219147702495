<template>
  <Button v-bind="btnParams.props" class="re-small" :style="btnParams.style"
    v-on:click.stop="createCardModalShow = true">
    <slot></slot>
    {{ title ? title : "新建" }}
  </Button>
  <Modal v-model="createCardModalShow" v-bind="modalParams.props" footer-hide :title="title">
    <Steps :current="current" style="padding: 6px;">
      <Step title="选择类型"></Step>
      <Step title="创建"></Step>
    </Steps>
    <div v-show="current == 0" style="padding: 24px;min-height: 150px;">
      <RadioGroup v-model="curVariety" @on-change="changeVariety">
        <Radio v-for="(item, i) in varieties" :key="i" :label="item.variety">
          {{ item.name }}
        </Radio>
      </RadioGroup>
    </div>
    <div style="padding: 12px" v-if="createCardModalShow && current == 1">
      <FromPlotRender v-if="curVarietyForm.form.scene" :scene="curVarietyForm.form.scene"
        :params="{ coordinate, formModel: formModel, requestParams, branch }" @submit="onSubmit" />
    </div>
    <div style="margin-top:16px;margin-right: 12px;">
      <Button v-if="current !== 0" style="margin-right: 12px;" type="" @click="back">上一步</Button>
      <Button v-if="current !== 1" type="primary" @click="next">下一步</Button>
    </div>

  </Modal>
</template>
<script>
import FromPlotRender from "@/components/plotRender/FromPlotRender";
import { useNodeStore } from '@/stores/node';

export default {
  props: {
    //  form: Object,
    title: String,
    branch: [String, Array],
    coordinate: { type: [Array, String, undefined] },
    varieties: {
      type: Array,
      default: function () {
        return [
          {
            form: {
              scene: "",
              formModel: {},
            },
            name: "新建",
            variety: ""
          }
        ];
      },
    },
    btnParams: {
      type: Object,
      default: function () {
        return {
          props: {
            long: false,
            type: "default",
            size: "auto",
          },
        };
      },
    },
    modalParams: {
      type: Object,
      default: function () {
        return {
          props: {
            styles: {
              top: "60px",
              width: "1280px",
            },
            title: "新建",
          },
        };
      },
    },
    requestParams: Object,
  },
  inject: {
    _curNode: {
      from: '_curNode',
      default: {}
    }
  },
  components: {
    FromPlotRender
  },
  data() {
    return {
      curVariety: "",
      buttonProps: {
        type: "default",
        size: "small",
      },
      defaultParams: {},
      cardTypeIndex: 0,
      createCardModalShow: false,
      typeSelectModal: false, // 选择卡片类型弹窗
      createCardModal: false, // 创建卡片弹窗
      cardTypes: [],
      createformModel: {},
      current: 0,
      curVarietyForm: {}
    };
  },
  computed: {
    // curVarietyForm: {
    //   get: function () {
    //     console.log("this.varieties", this.varieties, this.curVariety)
    //     return this.varieties.find(n => {
    //       return n.variety == this.curVariety
    //     })
    //   }
    // }
    formModel: {
      get: function () {
        if (this.curVarietyForm.form.formModel) {
          Object.assign(this.createformModel, this.curVarietyForm.form.formModel);
        }
        return this.createformModel;
      },
      set: function (value) {
        this.createformModel = value;
      },
    },
  },
  mounted() {

  },
  methods: {
    changeVariety(v) {
      console.log("this.varieties", this.varieties, this.curVariety, v)
      this.curVarietyForm = this.varieties.find(n => {
        return n.variety == this.curVariety
      })
      console.log(this.curVarietyForm)
    },
    onSubmit(card) {
      this.$emit("submit", card);
    },
    next() {
      if (this.current < 3) {
        this.current++
      }
    },
    back() {
      if (this.current > 0) {
        this.current--;
      } else {

      }
    },
  },
  provide: function () {
    return {
      preIds: this.curVarietyForm?.form?.preIds,
      formSetting: () => this.curVarietyForm?.form,
      curNode: this.curVarietyForm?.form?.curNode,
      getCurNode: () => {

        if (this.coordinate) {

          return this.nodeStore.getNode({
            coordinate: this.coordinate,
            frameId: this.nodeStore.frame._id,
          });
        } else {
          return this._curNode//this.curContainer();
        }
      },
      branch: () => this.branch
    };
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less">
.item-label {
  padding: 8px;
}
</style>
