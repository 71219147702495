<template>
  <component :is="comp" transfer v-model="modelValue" :type="inputParams.innerType" :format="inputParams.format"
    placeholder="清选择..." :disabled="disabled">
  </component>
</template>

<script>
import dayjs from 'dayjs';
import { DatePicker, TimePicker } from "view-ui-plus";
import { getFun, setFun } from '../modelValue.js';

export default {
  components: {},
  props: {
    inputParams: Object,
    disabled: Boolean,
  },
  inject: ["formModel", "keyPath"],
  data() {
    return {
      curTime: undefined,
    };
  },
  watch: {
    curTime(val) {
      if (this.curTime) {
        this.formModel[
          this.inputParams.id
        ] = this.curTime.toISOString();
      }
    },
  },
  computed: {
    modelValue: {
      get: function () {

        const dateObj = getFun(this.formModel, this.inputParams, this.keyPath)
        console.log('dateObj', dateObj)

        if (typeof (dateObj) !== 'string' && typeof (dateObj) !== 'number' && typeof (dateObj) !== 'undefined') {
          let date
          switch (this.inputParams.innerType) {
            case "date": date = `${dateObj?.year}-${String(dateObj?.month).padStart(2, '0')}-${String(dateObj?.day).padStart(2, '0')}`; break
            case "time": break
            case "datetime": date = new Date(
              dateObj.year,
              dateObj.month - 1,  // 月份减去1，因为月份是从0开始的
              dateObj.day,
              dateObj.hour,
              dateObj.minute,
              dateObj.sec,
              Math.floor(dateObj.microsec / 1000) // 微秒转换为毫秒
            );
          }
          console.log('date', date)
          return date
        }
        return dateObj
      },
      set: function (newValue) {
        console.log('newValue', newValue)
        let format
        switch (this.inputParams.innerType) {
          case "date": format = 'YYYY-MM-DD'; break
          case "time": format = 'hh:mm:ss'; break
          case "datetime": format = 'YYYY-MM-DDThh:mm:ss'
        }
        let dateString = dayjs(newValue).format(format);
        console.log('newValue2', dateString)

        return setFun(this.formModel, this.inputParams, dateString, this.keyPath)
      },
    },
    comp() {
      if (
        this.inputParams.innerType === "time" ||
        this.inputParams.innerType === "timerange"
      ) {
        return TimePicker;
      } else {
        return DatePicker;
      }
    },
  },
  mounted() {
    if (this.formModel[this.inputParams.id]) {
      this.curTime = new Date(this.formModel[this.inputParams.id]);
    }
  },
};
export function initItem(input) {
  input.innerType = "date";
  return input;
}
</script>
