<template>
  <i-input type="textarea" v-model="form[item.id]" :show-word-limit="item.maxLength > 0"
    :maxlength="item.maxLength > 0 ? item.maxLength : null" :placeholder="item.placeholder" :disabled="disabled" />
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    item: Object,
    form: Object,
  },
  components: {
  },
};
export const isStringType = true;
</script>

<style lang="less" scoped></style>
