<template>
  <div class="grid-value foreign-value" @click="showCardModal = true">
    <div v-if="inputParams.itemPlot">
      <PlotRender :scene="inputParams.itemPlot" :params="{ _curNode: _curNode, preNode: preNode }" />
    </div>
    <Modal v-model="showCardModal" :styles="cardModalStyle" footer-hide>
      <PlotRender v-if="showCardModal && inputParams.cardPlot" :params="{ _curNode: _curNode, preNode: preNode }"
        :scene="inputParams.cardPlot">
      </PlotRender>
    </Modal>
  </div>
</template>

<script>
import { useNodeStore } from '@/stores/node';

export default {
  inject: ["_curNode", "curNode", "preNode"],
  props: {
    inputParams: Object,
    disabled: Boolean,
    cardModalStyle: {
      type: Object,
      default: function () {
        return {
          top: "100px",
          width: "720px",
          minWidth: "920px",
          maxWidth: "1600px",
        };
      },
    },
  },
  data() {
    return {
      showCardModal: false,
    };
  },
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this.curNode()?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        let edge = this.curNode().edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })
        return edge?.props
      }
    },
    foreignNode() {
      if (!this.formModelEntity[this.inputParams.id]?._id) {
        return null
      }
      const foreignNode = this.nodeStore.getNodeById(this.formModelEntity[this.inputParams.id]._id);

      if (foreignNode) {
        return foreignNode;
      } else {
        this.nodeStore.initNodeById({
          id: this.formModelEntity[this.inputParams.id]._id,
          frameId: this.nodeStore.frame._id,
        });
      }
    },
  },

  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
export const isStringType = true;
</script>
<style lang="less" scoped>
@import "../cardGrid.less";

.foreign-value:hover {
  background: #88acdd30;
}
</style>
