<template>
  <div class=" grid-value grid-image">
    <Image :src="formModelEntity?.[inputParams.prop]" :fit="inputParams.fit" preview :class="[inputParams.imageSize]"
      :style="inputParams.style" :preview-list="[formModelEntity?.[inputParams.prop]]" :initial-index="0">
      <template #error>
        <!-- <Icon type="ios-image-outline" size="24" color="#ccc" /> -->
        <div></div>
      </template>
    </Image>
  </div>
</template>

<script>

export default {
  props: {
    inputParams: Object,
  },
  inject: ["_curNode", "curNode", "preNode"],
  computed: {
    formModelEntity() {
      if (this.inputParams.varietyType == 'tag') {
        const entity = this.curNode()?.tag?.[this.inputParams.variety];
        return entity;

      } else if (this.inputParams.varietyType == 'edge') {
        console.log("edge", this.curNode().edge, this.preNode, this.inputParams.variety)
        let edge = this.curNode().edge.find(e => {
          return e.src === this.preNode.vid && this.inputParams.variety === e.name
        })
        console.log("edge2", edge)

        return edge?.props
        // if (this.inputParams.variety === this._curNode?.name) {
        //   const entity = this._curNode?.props;
        //   return entity
        // }

      }
    },
    unit() {
      return this.inputParams?.unit
    }
  },
};
</script>
<style lang="less" scoped>
@import '../cardGrid.less';

.grid-image {
  .rect120 {
    width: 120px;
    height: 48px;
  }

  .sq26 {
    width: 26px;
    height: 26px;
  }

  .sq48 {
    width: 48px;
    height: 48px;
  }

  .rect240 {
    width: 240px;
    height: 96px;

  }
}
</style>
