<script setup>
import { useNodeStore } from '@/stores/node';
import * as AMapLoader from '@amap/amap-jsapi-loader';
import * as _ from "lodash";
import { computed, reactive, watch } from 'vue';
const nodeStore = useNodeStore()

const props = defineProps({
    data: Object,//把option传进来就行了
    mapConfig: Object,
    keys: Object,//(不知道为啥传进来的key是undefined)
    width: Number,
    height: Number,
    branch: String,
    format: Object,
})

const { data, mapConfig, keys, width, height, branch, format } = props

window._AMapSecurityConfig = {
    securityJsCode: keys.securityJsCode
}

const formatedNodes = computed(() => {

    const nodes = reactive(nodeStore.getBranch({
        branchId: branch,
    }))

    if (!nodes) {
        return []
    }

    const formatedNodes = nodes?.nodes?.map(n => {
        const formatedNode = Object.assign({}, format.struct)
        for (let key in formatedNode) {
            formatedNode[key] = _.get(n, formatedNode[key])
        }
        return formatedNode;
    })
    return reactive(formatedNodes)
})


let map = null
let vectors = []
let markers = []

watch(formatedNodes, (newValue, oldValue) => {
    console.log('branch newValue', newValue, oldValue)

    AMapLoader.load({
        "key": keys.key,//"7e3965ca2cb35f1144c8b594f2d9f07d",              // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.1Beta",       // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [],
    }).then(AMap => {
        map = new AMap.Map("map", mapConfig) //实例化地图

        let normalMarker = new AMap.Marker({
            anchor: 'bottom-center',
            offset: [0, -15],
        }); //用于点击事件显示的marker

        const icon = {
            type: 'image',
            image: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
            size: [10, 15],
            anchor: 'bottom-center',
        };

        data.series.forEach(item => {
            let vector = null
            if (item.type === 'points') {
                const offset = item.offset
                const icon = item.icon ? item.icon : 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png'
                newValue?.map(positionItem => {
                    const position = new AMap.LngLat(
                        positionItem.lng,
                        positionItem.lat
                    ); //Marker 经纬度

                    const marker = new AMap.Marker({
                        position: position,
                        icon: icon,
                        //  content: markerContent, //将 html 传给 content
                        offset: new AMap.Pixel(offset.center, offset.bottom), //以 icon 的 [center bottom] 为原点
                    });
                    map.add(marker);
                    //markers.push(marker);
                    console.log('marker', marker)
                    console.log('item.data', item.data)
                })

                // item.data?.map(position => {
                //     const positionObj = new AMap.LngLat(
                //         104.065735,
                //         30.659462
                //         // position[0],
                //         // position[1]
                //     );
                //     const marker = new AMap.Marker({
                //         // icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                //         position: positionObj,
                //         // offset: new AMap.Pixel(-13, -30)
                //         offset: new AMap.Pixel(offset.center, offset.bottom)
                //     });
                //     // marker.setMap(map);
                //     map.add(marker);
                //     markers.push(marker);
                // })

            } else if (item.type === 'line') {
                //折线
                vector = new AMap.Polyline({
                    path: item.data,
                    strokeColor: item.lineStyle.color,
                    strokeWeight: item.lineStyle.width,
                    strokeStyle: item.lineStyle.type,
                })
                console.log(item.data)
                //把折线上的点遍历一遍
                item.data.forEach(pos => {
                    const labelMarker = new AMap.LabelMarker({
                        position: pos,
                        icon
                    })
                    markers.push(labelMarker);

                    //点击事件
                    labelMarker.on('click', (e) => {
                        const position = e.data.data && e.data.data.position
                        if (position) {
                            //设置点击后要出现的内容
                            normalMarker.setContent(
                                '<div class="amap-info-window">'
                                + position +
                                '<div class="amap-info-sharp"></div>' +
                                '</div>');
                            normalMarker.setPosition(position);
                            map.add(normalMarker);
                        }
                    })
                    labelMarker.on('mouseout', function () {
                        map.remove(normalMarker);
                    });
                })

            } else if (item.type === 'area') {
                //多边形
                vector = new AMap.Polygon({
                    path: item.data,
                    strokeColor: item.lineStyle.color,
                    strokeWeight: item.lineStyle.width,
                    strokeStyle: item.lineStyle.type,
                    fillColor: "#1791fc", //填充色
                    fillOpacity: 0.35//填充透明度
                })
                console.log(vector)
            } else {
                //其他类型数据
            }
            if (vector !== null) {
                vectors.push(vector)
            }
        });


        // 初始化图层
        let overlayGroup = new AMap.OverlayGroup(vectors)
        let LabelsLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            collision: false
        })
        //将点标记加载到labels图层
        LabelsLayer.add(markers);
        //加载图层
        map.add(overlayGroup);
        map.add(LabelsLayer)
    }).catch(e => {
        console.log(e);
    })
})



</script>

<template>
    <div id="map" class="map-container" :style="{ width: `${props.width}px`, height: `${props.height}px` }"></div>
</template>

<style scoped></style>