<template>
  <div>
    <FileUpload ref="upload" :inputId="id" :value="files" @update:modelValue="updatetValue" @input-file="inputFile"
      @input-filter="inputFilter" :multiple="true" :directory="directory" class="file-upload">
      <div class="file-upload-wrap">
        <div v-for="(file, i) in files" :style="{ backgroundImage: `url(${file.imgUrl})` }" class="d-flex file-item"
          :key="i">

          <i class="bi bi-x-circle remove-btn" @click.stop="handleRemove(i)"></i>
          <i class="bi bi-check-circle success-btn" v-if="file.success"></i>
          {{ file.name }}
          <Progress :percent="Number(file.progress)" v-show="file.active" style="position: absolute;bottom: 39%;"
            :stroke-width="5" status="active" hide-info></Progress>
        </div>
        <div class="file-form-warp">
          <div>
            <i class="bi bi-cloud-arrow-up-fill" style="font-size: 16px;color: #1a3a66;"></i>
            点击或拖拽 上传文件
          </div>
        </div>
      </div>
    </FileUpload>
    <!-- <button @click="fileAdded()"
            type="button">
      get url
    </button>
    <button
      v-show="target && (!$refs.upload || !$refs.upload.active)"
      @click.prevent="$refs.upload.active = true"
      type="button"
    >
      开始上传
    </button> -->
    <!-- <Button v-show="$refs.upload && $refs.upload.active"
            @click.prevent="$refs.upload.active = false"
            type="button">
      停止上传
    </Button> -->
  </div>
</template>

<script>
import { applyFile } from "@/api/frame";
import { useNodeStore } from '@/stores/node';
import { v4 as uuidv4 } from "uuid";
import * as VueUploadComponent from "vue-upload-component";

const variety = "file";
export default {
  components: {
    FileUpload: VueUploadComponent
  },
  props: {
    "preId": String,
    "id": String,
    "provider": { type: String, default: 'default' },
    fileFilter: {
      type: Array,
    },
    directory: Boolean
  },
  data() {
    return {
      files: [],
      uuid: "",
      target: "",
      imgUrl: "",
      imgUrlT: "",
      query: {},
      postPolicy: undefined,
      uploadRef: "",
      card: {
        frameId: this.nodeStore.frame._id,
        preId: this.preId,
        payload: {
          name: "",
        },
        variety: "file",
      },
      uuid: "",
      rawFiles: []
    };
  },
  methods: {
    updatetValue(value) {
      // console.log("this.files", files)
      if (this.directory) {
        this.uuid = uuidv4()
      }
      console.log("this.uuid", this.uuid)
      // this.uuid = uuidv4()
      this.files = value
    },
    fileAdded(file) {
      console.log("file1", file)
      applyFile({ provider: this.provider, file, uuid: this.uuid }).then((res) => {
        const postPolicy = res.data.data.postPolicy;
        const uuid = res.data.data.id;

        file.postAction = postPolicy.postURL;
        // file.query = postPolicy.formData;

        file.postAction = postPolicy.postURL;
        file.data = postPolicy.formData;

        file.imgUrl_temp = postPolicy.postURL + '/' + postPolicy.formData.key;

        // if (!this.$refs.upload || !this.$refs.upload.active) {
        //   this.$refs.upload.active = true;
        file.card = {
          frameId: this.nodeStore.frame._id,
          preId: this.preId,
          payload: {},
          variety: variety,
        };
        file.card.uuid = uuid;

        file.card.payload.name = file.name;
        file.card.payload.size = file.size;
        file.card.payload.url = file.imgUrl_temp;
        const fileType = file.type.split("/");
        file.card.payload.fileType = fileType;
        const suffix = fileType[fileType.length - 1].split(".");
        file.card.payload.suffix = suffix[suffix.length - 1];

        this.$refs.upload.update(file, { active: true });
        //        this.$refs[this.id].update(file, { active: true });
      });

      // this.$refs.upload.upload();
    },
    handleRemove(index) {
      this.files.splice(index, 1);
    },

    /**
     * Has changed
     * @param  Object|undefined   newFile   只读
     * @param  Object|undefined   oldFile   只读
     * @return undefined
     */
    inputFile(newFile, oldFile) {
      console.log("inputFile", newFile)

      if (newFile && !oldFile) {
        // 添加文件
        this.fileAdded(newFile);
      }

      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // 获得相应数据
        newFile.imgUrl = newFile.imgUrl_temp;
        this.fileDone(oldFile);
        console.log("response", newFile.response, this.imgUrl);

        if (newFile.xhr) {
          //  获得响应状态码
          console.log("status", newFile.xhr.status);
        }
      }
    },
    fileDone(file) {
      console.log("oldFile", file);
      this.$emit("save-card", file.card.payload.url);

      // createCard({
      //   variety: variety,
      //   formModel: file.card,
      //   frameId: this.nodeStore.frame._id,
      //   preId: this.preId,
      // })
      //   .then((res) => {
      //     const card = res.data.data;
      //     this.nodeStore.initNode(card)
      //     // this.$store.commit("arr_card/ADD_CARD", {
      //     //   card: card,
      //     // });
      //     this.$emit("save-card", card);
      //   })
      //   .catch((error) => {
      //     console.error(error)
      //     if (error.response) {
      //       this.$Message.error(`${error.response.data.message}`);
      //     } else {
      //       this.$Message.error("系统错误!");
      //     }
      //   });
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   读写
     * @param  Object|undefined   oldFile   只读
     * @param  Function           prevent   阻止回调
     * @return undefined
     */
    inputFilter(newFile, oldFile, prevent) {
      console.log("newFile1", newFile)
      // let regExpStr ="\.()$"
      let regExpStr = ""
      if (!this.fileFilter) {
        return false
      }
      for (let type of this.fileFilter) {
        let str = ""
        switch (type) {
          case "pic": str = "jpeg|jpe|jpg|gif|png|webp"; break;
          case "video": str = "mp4|avi|webm"; break;
          case "pdf": str = "pdf"; break;
          case "word": str = "doc|docx|dot|dotx|docm"; break;
          case "ppt": str = "ppt|pptx|pptm|pps|ppsx"; break;
          case "excel": str = "xls|xlsx|xlsm|xlsb|csv"; break;
          case "flod": str = "ini"; break;
        }
        if (regExpStr && str) {
          regExpStr += '|'
          regExpStr += str
        } else {
          regExpStr += str
        }
      }
      let reg = new RegExp(`\.(${regExpStr})$`, "i");
      if (newFile && !oldFile) {
        // 过滤不是图片后缀的文件
        const valid = reg.test(newFile.name)
        if (!valid) {
          console.log("valid", valid, this.fileFilter)
          return prevent();
        }
        // if (!/\.(jpeg|jpe|jpg|gif|png|webp|mp4|avi)$/i.test(newFile.name)) {
        //   return prevent();
        // }
      }

      // 创建 blob 字段 用于图片预览
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
  },
  mounted() {
    // applyFile({ provider: "xuanyuanjiang" }).then((res) => {
    //   this.postPolicy = res.data.data.postPolicy;
    //   this.uuid = res.data.data.id;
    //   this.target = this.postPolicy.postURL;
    //   this.query = this.postPolicy.formData;
    //   // this.imgUrl = this.postPolicy.postURL + this.postPolicy.formData.key;
    // });
  },
  created() {
    // this.uploadRef = "file_" + this._uid;
  },
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore
    }
  }
};
</script>

<style scoped>
.file-form-warp {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-uploads {
  min-height: 100px;
  width: 100%;
  border: 1px dashed #dcdee2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload-wrap {
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.file-item {
  width: 106px;
  height: 106px;
  z-index: 100;
  background-size: cover;
  position: relative;
  align-items: center;
  justify-content: center;
  color: #919191;
  outline: 1px solid #eee;
}

.remove-btn {
  width: 20px;
  height: 20px;
  font-size: 16px;
  position: absolute;
  right: 4px;
  top: 4px;
  line-height: 20px;
  color: #fff;
  text-shadow: 0px 1px #585858;
}

.success-btn {
  width: 20px;
  height: 20px;
  font-size: 16px;
  position: absolute;
  left: 4px;
  top: 4px;
  line-height: 20px;
  color: #4ab465;
  /* text-shadow: 0px 1px #585858; */
}

.file-item:hover .remove-btn {
  color: rgb(235, 34, 34);
}
</style>
