<template>
  <FromPlotRender v-if="form.scene" :scene="form.scene" :params="{ coordinate, formModel, requestParams, branch }"
    @submit="onSubmit" />
</template>
<script>
import FromPlotRender from "@/components/plotRender/FromPlotRender";
import { useNodeStore } from '@/stores/node';

export default {
  props: {
    form: Object,
    branch: { type: [Array, String, undefined] },

    requestParams: Object,
  },
  // inject: {
  //   //curContainer: "curContainer",
  //   _curNode: "_curNode"

  // },
  inject: {
    _curNode: {
      from: '_curNode',
      default: {}
    }
  },
  components: {
    FromPlotRender
    // PlotFormRender: () => import("component/common/PlotControl/PlotFormRender"),
  },
  data() {
    return {
      buttonProps: {
        type: "default",
        size: "small",
      },
      defaultParams: {},
      cardTypeIndex: 0,
      createCardModalShow: false,
      typeSelectModal: false, // 选择卡片类型弹窗
      createCardModal: false, // 创建卡片弹窗
      cardTypes: [],
      createformModel: {},
    };
  },
  computed: {
    formDef: function () {
      // this.nodeStore.
      // const datapoolForm = this.$store.getters["datapool/getForm"](
      //   this.form.scene
      // );
      // let formInternal = cloneDeep(datapoolForm);
      // if (formInternal) {
      //   return formInternal;
      // } else {
      //   return false;
      // }
    },
    formModel: {
      get: function () {
        if (this.form.formModel) {
          Object.assign(this.createformModel, this.form.formModel);
        }
        return this.createformModel;
      },
      set: function (value) {
        this.createformModel = value;
      },
    },
  },
  mounted() {
    if (this.form.formModel) {
      Object.assign(this.createformModel, this.form.formModel);
    }
  },
  methods: {
    onSubmit(card) {
      this.$emit("submit", card);
    }
  },
  provide: function () {
    return {
      preIds: this.form.preIds,
      formSetting: () => this.form,
      curNode: this.form.curNode,
      getCurNode: () => {

        if (this.coordinate) {

          return this.nodeStore.getNode({
            coordinate: this.coordinate,
            frameId: this.nodeStore.frame._id,
          });
        } else {
          return this._curNode//this.curContainer();
        }
      },
      branch: () => this.branch
    };
  },
  setup() {

    const nodeStore = useNodeStore()

    return {
      nodeStore
    }
  }
};
</script>
<style scoped lang="less">
.item-label {
  padding: 8px;
}
</style>
