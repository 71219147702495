<template>
  <div class="vue-form-creator__header">
    <span>{{ item.label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    form: Object
  }
};
export const isNotInput = true;
</script>

<style lang="less">
.vue-form-creator__header {
  font-weight: 500;
  margin: 0 0 24px;
  // margin: 30px 0 10px;
  position: relative;

  span {
    display: inline-block;
    background: #fff;
    padding: 0 5px 0 18px;
    position: relative;
    margin-left: 30px;
    font-size: 14px;
  }
}

.vue-form-creator__header::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #eee;
  position: absolute;
  top: 10px;
  left: 0;
}
</style>
