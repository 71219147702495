<template>
    <div style="border: 1px solid #ccc">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
        <Editor style="height: 500px; overflow-y: hidden;" v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode"
            @onCreated="handleCreated" />
    </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css'; // 引入 css

import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { onBeforeUnmount, shallowRef } from 'vue';

export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    computed: {
        valueHtml: {
            get () {
                return this.modelValue
            },
            set (value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    components: { Editor, Toolbar },
    setup () {
        // 编辑器实例，必须用 shallowRef
        const editorRef = shallowRef()

        const toolbarConfig = {}
        const editorConfig = { placeholder: '请输入内容...' }

        // 组件销毁时，也及时销毁编辑器
        onBeforeUnmount(() => {
            const editor = editorRef.value
            if (editor == null) return
            editor.destroy()
        })

        const handleCreated = (editor) => {
            editorRef.value = editor // 记录 editor 实例，重要！
        }

        return {
            editorRef,
            //  valueHtml,
            mode: 'default', // 或 'simple'
            toolbarConfig,
            editorConfig,
            handleCreated
        };
    }
}
</script>    
<style >
.w-e-bar-item {
    padding: 0 !important;
}

.w-e-bar {
    padding: 0 0px !important;
}

.w-e-bar-item button {
    padding: 0 8px !important;
}

.w-e-bar-item {
    height: 34px !important;
}

.w-e-bar-divider {
    height: 34px !important;
}

.w-e-bar-item-group .w-e-bar-item-menus-container {
    margin-top: 32px;

}
</style>