<template>
  <Form :model="formPolicy" label-position="left" :label-width="160" class="p-2">
    <FormItem label="行为action">
      <Input v-model="formPolicy.action"></Input>
    </FormItem>
    <FormItem label="正负positive">
      <InputNumber v-model="formPolicy.positive"></InputNumber>
    </FormItem>
    <FormItem label="行为weight">
      <InputNumber :min="-1" v-model="formPolicy.weight" />
    </FormItem>
    <divider plain size="small">上级subject</divider>

    <FormItem label="上级起始 subject.start" v-if="formPolicy.subject">
      <OdInput v-model="formPolicy.subject.start" />
    </FormItem>
    <FormItem label="上级结束 subject.end" v-if="formPolicy.subject && formPolicy.subject.method == 0">
      <OdInput v-model="formPolicy.subject.end" />
    </FormItem>
    <div class="d-flex" style="gap: 20px;" v-if="formPolicy.subject">
      <FormItem label="上级方法 subject.method" class="pr-5">
        <Select v-model="formPolicy.subject.method">
          <Option :value="1">纵向</Option>
          <Option :value="0">横向</Option>
        </Select>
      </FormItem>
      <FormItem label="上级深度 subject.downlevel">
        <InputNumber :min="0" v-model="formPolicy.subject.downlevel" />
      </FormItem>
    </div>
    <divider plain size="small">下级object</divider>
    <FormItem label="下级起始 object.start" v-if="formPolicy.object">
      <OdInput v-model="formPolicy.object.start" />
    </FormItem>
    <FormItem label="下级结束 object.end" v-if="formPolicy.object && formPolicy.object.method == 0">
      <OdInput v-model="formPolicy.object.end" />
    </FormItem>
    <div class="d-flex" style="gap: 20px;" v-if="formPolicy.object">
      <FormItem label="下级方法 object.method">
        <Select v-model="formPolicy.object.method">
          <Option :value="1">纵向</Option>
          <Option :value="0">横向</Option>
        </Select>
      </FormItem>
      <FormItem label="下级深度 object.downlevel">
        <InputNumber :min="0" v-model="formPolicy.object.downlevel" />
      </FormItem>
    </div>
  </Form>
</template>

<script>
import { policyCreate } from "@/api/frame";
import OdInput from "@/components/common/odInput/OdInput";
import { useNodeStore } from '@/stores/node';

export default {
  setup() {
    const nodeStore = useNodeStore()
    return {
      nodeStore,
    }
  },
  props: ["formPolicy"],
  components: {
    OdInput
  },
  data() {
    return {
      modalCreate: false,
    };
  },
  methods: {
    saveChange() {
      const policy = { ...this.formPolicy, frameId: this.nodeStore.frame._id };
      policyCreate(policy).then((res) => {
        this.abilityList.push(res.data.data);
      });
    },
  },
  mounted() {

  },
};
</script>

<style scoped></style>
